import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import { defaultEmptyMember, roleOptions } from "../defaults";
import {
  refreshInvitesList,
  setFocusedMember,
  toggleMemberModal,
} from "../../../../../redux/membersReducer/action";
import {
  API_SERVER_URL,
  useAsyncFetchWithToken,
} from "../../../../../utils/urlHelpers";
import { SnackbarContext } from "../../../../../components/SnackbarContext/SnackbarContext";

const MemberModal = (props) => {
  var flow =
    props.focusedMember && Object.keys(props.focusedMember).length
      ? "update"
      : "create";
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  var createMode = !props.focusedMember?._id;
  const [memberDetails, setMemberDetails] = useState(defaultEmptyMember);
  const { run } = useAsyncFetchWithToken();
  useEffect(() => {
    if (props.focusedMember && Object.keys(props.focusedMember).length) {
      setMemberDetails({ ...props.focusedMember });
    } else {
      setMemberDetails(defaultEmptyMember);
    }
  }, [props.focusedMember]);
  const onConfirmClick = async () => {
    const { data, error } = await run(
      "POST",
      `${API_SERVER_URL}/manage/members`,
      { ...memberDetails }
    );
    if (data && !error) {
      props.refreshInvitesList();
      showSuccessSnackbar("Invitation sent successfully!");
    } else {
      showErrorSnackbar(error || "Something went wrong while inviting!");
    }
    props.toggleMemberModal();
  };

  const shouldDisableSave = () => {
    if (flow === "update" && props.focusedMember.role === memberDetails.role) {
      return true;
    } else if (
      flow === "create" &&
      (!memberDetails.role || !memberDetails.email)
    ) {
      return true;
    }
    return false;
  };
  return (
    <Dialog
      open={props.openMembersModal}
      maxWidth="sm"
      fullWidth
      onClose={props.toggleMemberModal}
    >
      <DialogTitle style={{ paddingBottom: 5 }}>
        {createMode ? "Add New Member" : "Modify Member"}
      </DialogTitle>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", gap: 15 }}
      >
        <DialogContentText>
          {createMode
            ? "Add new member with appropriate scope and abilities."
            : "You can only modify the scope for activated member, you can also remove the member from Members page."}
        </DialogContentText>
        <CustomInput
          label="Member Email"
          fullWidth
          disabled={flow === "update"}
          value={memberDetails.email}
          onChange={({ target }) =>
            setMemberDetails({ ...memberDetails, email: target.value })
          }
        />
        <CustomSelect
          label="Role"
          options={roleOptions}
          value={memberDetails.role}
          onSelectionChange={({ target }) =>
            setMemberDetails({ ...memberDetails, role: target.value })
          }
        />
      </DialogContent>
      <DialogActions style={{ paddingRight: 25, paddingBottom: 15 }}>
        <CustomButton
          label={props.cancelLabel ?? "Cancel"}
          onClick={props.toggleMemberModal}
          inverted={true}
        />
        <CustomButton
          disabled={shouldDisableSave()}
          label={props.confirmLabel ?? "Confirm"}
          onClick={onConfirmClick}
        />
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  ...store.membersReducer,
});
const mapActionsToProps = {
  setFocusedMember: setFocusedMember,
  toggleMemberModal: toggleMemberModal,
  refreshInvitesList: refreshInvitesList,
};
export default connect(mapStateToProps, mapActionsToProps)(MemberModal);
