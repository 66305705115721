import CATEGORY_ACTIONS from "./actionTypes";

const initialState = {
  categoriesLoading: false,
  categoriesList: [],
  pageDetails: { page: 0, limit: 20, totalPages: 0, totalResults: 0 },
  error: null,
  openCategorySideTray: false,
  focusedCategoryDetails: null,
};

const reducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case CATEGORY_ACTIONS.SET_CATEGORIES_LIST:
      newState.categoriesList = action.payload;
      if (!action.payload.length) {
        newState.pageDetails = {
          page: 0,
          limit: 20,
          totalPages: 0,
          totalResults: 0,
        };
      }
      return newState;
    case CATEGORY_ACTIONS.SET_CATEGORIES_LOADING:
      newState.categoriesLoading = action.payload;
      return newState;
    case CATEGORY_ACTIONS.SET_CATEGORIES_FETCH_ERROR:
      newState.error = action.payload;
      return newState;
    case CATEGORY_ACTIONS.SET_CATEGORIES_PAGE_DETAILS:
      newState.pageDetails = action.payload;
      return newState;
    case CATEGORY_ACTIONS.TOGGLE_CATEGORY_SIDETRAY_TRAY:
      newState.openCategorySideTray = !newState.openCategorySideTray;
      // on close setting the focused category as null
      newState.focusedCategoryDetails = newState.openCategorySideTray
        ? newState.focusedCategoryDetails
        : null;
      return newState;
    case CATEGORY_ACTIONS.SET_FOCUSED_CATEGORY:
      newState.focusedCategoryDetails = action.payload;
      return newState;
    default:
      return newState;
  }
};

export default reducer;
