import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import CustomButton from "../../../../../../components/CustomButton/CustomButton";
import CustomSwitch from "../../../../../../components/CustomSwitch/CustomSwitch";
import CustomSelect from "../../../../../../components/CustomSelect/CustomSelect";
import { AllApps, AppTypes } from "../../defaults";
import { gmcAvailableOptions } from "../appConfigs";
import { updateAppSettings } from "../../redux/common/actions";
import { connect, useDispatch } from "react-redux";
import { initGoogleMerchantCenter } from "../../redux/googleMerhcantCenter/actions";
import LinearLoading from "../../../../../../components/LoadingScreen/LinearLoading";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const useStyles = makeStyles((theme) => ({
  appIcon: {
    height: 60,
  },
  appInfoWrapper: {
    display: "flex",
    gap: 20,
    alignItems: "center",
    border: "1px solid rgb(145, 158, 171)",
    padding: 20,
    marginTop: 10,
    borderRadius: 10,
  },
  appName: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgb(33, 43, 54)",
  },
  appDescription: {
    fontWeight: 300,
    fontSize: 13,
    color: "rgb(33, 43, 54)",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
}));

const defaultConfigs = AllApps.find(
  (x) => x.appType === AppTypes.GoogleMerchantCenterContentApi
);

const GoogleMerhcantCenter = (props) => {
  const [appDetails, setAppDetails] = useState({ ...props.appDetails });

  const dispatch = useDispatch();

  useEffect(() => {
    const initReq = initGoogleMerchantCenter();
    dispatch(initReq);
  }, [dispatch]);

  const onUpdateSettingsClick = () => {
    const updateSettingsReq = updateAppSettings(
      AppTypes.GoogleMerchantCenterContentApi,
      appDetails.settings
    );
    dispatch(updateSettingsReq);
  };

  const onProductSettingsChange = (property, value) => {
    setAppDetails({
      ...appDetails,
      settings: {
        ...appDetails.settings,
        productSettings: {
          ...appDetails.settings?.productSettings,
          [property]: value,
        },
      },
    });
  };

  const onAppSettingsChange = (property, value) => {
    setAppDetails({
      ...appDetails,
      settings: { ...appDetails.settings, [property]: value },
    });
  };

  const classes = useStyles();
  return (
    <Box>
      {props.loading ? <LinearLoading /> : null}
      <Box className={classes.appInfoWrapper}>
        <img src={defaultConfigs.appIcon} alt="" className={classes.appIcon} />
        <Box style={{ flex: 1 }}>
          <Typography className={classes.appName}>
            {defaultConfigs.appName}
          </Typography>
          <Typography paragraph className={classes.appDescription}>
            {defaultConfigs.appDescription}
          </Typography>
        </Box>
        <CustomButton
          // disabled={loadingGoogleLoginLink}
          href={props.appLoginLink}
          icon={<PersonAddAltIcon />}
          label={
            appDetails.isConfigured
              ? "Use different account"
              : "Login to Google Merchcant Center"
          }
        />
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <CustomSwitch
          label="Should add new products to Google Merchant Center"
          onSwitchChange={({ target }) =>
            onAppSettingsChange("shouldAddNewProducts", target.checked)
          }
          checked={
            "shouldAddNewProducts" in appDetails.settings &&
            appDetails.settings["shouldAddNewProducts"]
          }
        />
        {"shouldAddNewProducts" in appDetails.settings &&
        appDetails.settings.shouldAddNewProducts ? (
          <Box className={classes.formWrapper}>
            <CustomSelect
              options={gmcAvailableOptions.contentLanguage}
              selection={
                appDetails.settings?.productSettings["contentLanguage"]
              }
              label="Content Language"
              onSelectionChange={({ target }) =>
                onProductSettingsChange("contentLanguage", target.value)
              }
            />
            <CustomSelect
              options={gmcAvailableOptions.targetCountry}
              selection={appDetails.settings?.productSettings["targetCountry"]}
              label="Target Country"
              onSelectionChange={({ target }) =>
                onProductSettingsChange("targetCountry", target.value)
              }
            />
            <CustomSelect
              options={props.availableMerchantIds.map((x) => ({
                label: x,
                value: x,
              }))}
              selection={appDetails.settings?.selectedMerchantId}
              label="Selected Merchant Id"
              onSelectionChange={({ target }) =>
                onAppSettingsChange("selectedMerchantId", target.value)
              }
            />
          </Box>
        ) : null}
      </Box>
      <CustomButton
        label={
          appDetails.isConfigured ? "Update App Settings" : "Add App Settings"
        }
        onClick={onUpdateSettingsClick}
        sx={{ mt: 1 }}
      />
    </Box>
  );
};

const mapStateToProps = (store) => ({
  loading: store.gmcReducer.loading,
  error: store.gmcReducer.error,
  appLoginLink: store.gmcReducer.appLoginLink,
  availableMerchantIds: store.gmcReducer.availableMerchantIds,
});

export default connect(mapStateToProps)(GoogleMerhcantCenter);
