import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    backgroundColor: "rgb(33, 43, 54)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
  formWrapper: {
    width: "max-content",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 10,
    alignItems: "center",
  },
  welcomInfo: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    padding: "0 20px 30px",
  },
  logo: {
    width: 100,
  },
  welcomeText: {
    fontSize: "21px !important",
    // fontWeight: "600 !important",
    color: "rgb(33, 43, 54)",
    textAlign: "center",
  },
  welcomeInviteText: {
    fontSize: "14px !important",
    // color: "#fff",
    lineHeight: "14px !important",
    color: "rgb(33, 43, 54)",
    textAlign: "center",
  },
  inviteFormWrapper: {
    width: "100%",
    padding: "20px 30px",
  },
  inviteForm: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
}));
export default useStyles;
