import React, { useEffect } from "react";
import ErrorScreen from "../../../../../components/ErrorScreen/ErrorScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  API_SERVER_URL,
  useAsyncFetchWithToken,
} from "../../../../../utils/urlHelpers";
import LoadingScreen from "../../../../../components/LoadingScreen/LoadingScreen";
import { Box } from "@mui/material";
import { AppTypes } from "../defaults";

const AdminAppLogin = (props) => {
  const [queryParameters] = useSearchParams();
  const { data, loading, error, run } = useAsyncFetchWithToken();
  var navigate = useNavigate();
  useEffect(() => {
    const ac = new AbortController();
    if (queryParameters.get("code") && queryParameters.get("state")) {
      run("POST", `${API_SERVER_URL}/manage/additionalApps/validateLoginCode`, {
        code: queryParameters.get("code"),
        state: queryParameters.get("state"),
      });
    }
    return () => ac.abort();
  }, [queryParameters, run]);
  useEffect(() => {
    if (!loading && data) {
      if (data?.data?.appType == AppTypes.GoogleMerchantCenterContentApi) {
        navigate(
          `${process.env.REACT_APP_DASHBOARD_PATH}/adminApps?configureApp=${AppTypes.GoogleMerchantCenterContentApi}`
        );
      }
    }
  }, [loading, error, data, navigate]);
  if (!queryParameters.get("code") || !queryParameters.get("state") || error)
    return <ErrorScreen errroText={error} />;
  return loading ? (
    <LoadingScreen loadingText="Tying to autheticate..." />
  ) : (
    <Box></Box>
  );
};

export default AdminAppLogin;
