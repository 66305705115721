const tableHeaderOptions = [
  {
    type: "text",
    label: "Category",
  },
  {
    type: "text",
    label: "Description",
  },
  {
    type: "text",
    label: "Created at",
  },
  {
    type: "text",
    label: "Publish",
  },
  {
    type: "blank",
    label: "",
  },
];
const getCategoryPreviewPageUrl = (category) => {
  return `${process.env.REACT_APP_FRONTEND_URI}/category/${encodeURI(
    category.categoryName?.toLowerCase()?.replaceAll(" ", "-")
  )}`;
};
export { tableHeaderOptions, getCategoryPreviewPageUrl };
