import PRODUCT_ACTIONS from "./actionTypes";

export const setProductsList = (products) => {
  return {
    type: PRODUCT_ACTIONS.SET_PRODUCTS_LIST,
    payload: products,
  };
};
export const setPageDetails = (details) => {
  return {
    type: PRODUCT_ACTIONS.SET_PAGE_DETAILS,
    payload: details,
  };
};
export const setFetchError = (error) => {
  return {
    type: PRODUCT_ACTIONS.SET_FETCH_ERROR,
    payload: error,
  };
};

export const setProductsLoading = (loadingState) => {
  return {
    type: PRODUCT_ACTIONS.SET_PRODUCTS_LOADING,
    payload: loadingState,
  };
};

export const toggleProductSidetrayTray = () => {
  return {
    type: PRODUCT_ACTIONS.TOGGLE_PRODUCT_SIDETRAY_TRAY,
  };
};
export const setFocusedProduct = (productDetails) => {
  return {
    type: PRODUCT_ACTIONS.SET_FOCUSED_PRODUCT,
    payload: productDetails,
  };
};
