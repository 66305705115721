export const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],
  [{ direction: "rtl" }, { align: [] }], //text direction
  ["link", "clean"], // link
];

export const defaultEmptyCategory = {
  _id: "",
  categoryName: "",
  imageUrl: "",
  state: "draft",
  shortDescription: "",
  additionalInformation: "",
};
