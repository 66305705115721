import MEMBER_ACTIONS from "./actionTypes";

export const setMembersList = (members) => {
  return {
    type: MEMBER_ACTIONS.SET_MEMBERS_LIST,
    payload: members,
  };
};
export const setMembersListError = (error) => {
  return {
    type: MEMBER_ACTIONS.SET_MEMBERS_FETCH_ERROR,
    payload: error,
  };
};

export const setMembersLoading = (loadingState) => {
  return {
    type: MEMBER_ACTIONS.SET_MEMBERS_LOADING,
    payload: loadingState,
  };
};

export const toggleMemberModal = () => {
  return {
    type: MEMBER_ACTIONS.TOGGLE_MEMBER_MODAL,
  };
};
export const setFocusedMember = (memberDetails) => {
  return {
    type: MEMBER_ACTIONS.SET_FOCUSED_MEMBER,
    payload: memberDetails,
  };
};

export const setLoadMembers = (loadState) => {
  return {
    type: MEMBER_ACTIONS.SET_LOAD_MEMBERS,
    payload: loadState,
  };
};
export const refreshMembersList = () => {
  return {
    type: MEMBER_ACTIONS.REFRESH_MEMBERS_LIST,
    payload: new Date().getTime(),
  };
};
export const setInvitesLoading = (loadingState) => {
  return {
    type: MEMBER_ACTIONS.SET_INVITES_LOADING,
    payload: loadingState,
  };
};
export const setLoadInvitations = (loadState) => {
  return {
    type: MEMBER_ACTIONS.SET_LOAD_INVITATIONS,
    payload: loadState,
  };
};

export const setInvitationsList = (invites) => {
  return {
    type: MEMBER_ACTIONS.SET_INVITES_LIST,
    payload: invites,
  };
};

export const setFocusedInvite = (invite) => {
  return {
    type: MEMBER_ACTIONS.SET_FOCUSED_INVITE,
    payload: invite,
  };
};

export const changeFocusedTab = (tabToFocusOn) => {
  return {
    type: MEMBER_ACTIONS.SET_FOCUSED_TAB,
    payload: tabToFocusOn,
  };
};
export const refreshInvitesList = () => {
  return {
    type: MEMBER_ACTIONS.REFRESH_INVITES_LIST,
    payload: new Date().getTime(),
  };
};
