import { makeStyles } from "@material-ui/styles";
import { Button, Tooltip } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  basicButton: {
    borderRadius: 8,
    color: "#fff !important",
    display: "flex",
    alignItems: "center",
    gap: 4,
    textTransform: "none",
    backgroundColor: "rgb(33, 43, 54) !important",
    "&:hover": {
      backgroundColor: "rgb(69, 79, 91) !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgb(69, 79, 91) !important",
      cursor: "not-allowed",
    },
  },
  invertedBasicButton: {
    borderRadius: 8,
    color: "rgb(33, 43, 54) !important",
    display: "flex",
    alignItems: "center",
    gap: 4,
    textTransform: "none",
    backgroundColor: "#fff",
    border: "1px solid rgb(33, 43, 54)",
    "&:hover": {
      backgroundColor: "rgb(255, 255, 255, 0.7)",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      border: "1px solid rgba(50, 50, 50, 0.1)",
      color: "rgba(50, 50, 50, 0.6) !important",
      cursor: "not-allowed",
    },
  },
  disabledCursor: {
    cursor: "not-allowed",
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.tooltipText}
      disableFocusListener={!props.tooltipText}
      disableHoverListener={!props.tooltipText}
      disableTouchListener={!props.tooltipText}
    >
      <span className={props.disabled ? classes.disabledCursor : ""}>
        <Button
          className={
            props?.inverted ? classes.invertedBasicButton : classes.basicButton
          }
          onClick={props.onClick}
          href={props.href ?? null}
          disabled={props.disabled ?? false}
          style={props.style ?? {}}
          sx={props.sx ?? {}}
          type={props.type}
        >
          {props.icon ?? null}
          {props.label}
        </Button>
      </span>
    </Tooltip>
  );
};

export default CustomButton;
