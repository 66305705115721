import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

import CustomInput from "../../../../../components/CustomInput/CustomInput";
import { AllApps } from "../defaults";
import AppCard from "./AppCard";
import { useSearchParams } from "react-router-dom";

const AppsBrowserModal = (props) => {
  const [searchedAppName, setSearchedAppName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const goToSettingsClick = (appType) => {
    setSearchParams({ configureApp: appType });
    props.onClose();
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth onClose={props.onClose}>
      <DialogTitle style={{ paddingBottom: 5 }}>Admin Apps Browser</DialogTitle>
      <DialogContent>
        <DialogContentText
          fontSize={14}
          style={{
            position: "sticky",
            top: 0,
            background: "#fff",
            paddingBottom: 20,
            zIndex: 10,
          }}
        >
          You can browse apps that are avilable, and for new apps support
          contact tech team.
        </DialogContentText>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomInput
            label="App Name"
            style={{
              position: "sticky",
              top: 42,
              paddingBottom: 10,
              backgroundColor: "#fff",
            }}
            onChange={({ target }) => setSearchedAppName(target.value)}
          />
          <Box
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              padding: 1,
            }}
          >
            {AllApps.map((app, idx) => {
              app.isConfigured = props?.alreadyConfiguredApps?.find(
                (x) => x.appType === app.appType
              );
              return (
                <AppCard
                  key={idx}
                  appDetails={app}
                  onAddAppClick={() => props.onAddAppClick(app)}
                  goToSettingsClick={goToSettingsClick}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppsBrowserModal;
