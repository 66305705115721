import React from "react";

import { Route, Routes } from "react-router-dom";
import AdminAppLogin from "./components/AdminAppLogin";

import { Provider } from "react-redux";
import store from "./redux/store";
import AdminAppsBody from "./AdminAppsBody";

const AdminAppRoutes = (props) => {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/oauthCallback" element={<AdminAppLogin />} />
        <Route exact path="/" element={<AdminAppsBody />} />
      </Routes>
    </Provider>
  );
};

export default AdminAppRoutes;
