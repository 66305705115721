import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      borderBottom: "1px dashed rgb(241, 243, 244) !important",
    },
  },
  tableHeader: {
    backgroundColor: "rgb(244, 246, 248)",
  },
  activeTableHeader: {
    backgroundColor: "rgb(200, 250, 214)",
  },
  tableHeaderTitles: {
    fontSize: 14,
    color: "rgb(99, 115, 129)",
  },
  tableHeaderChechbox: {
    padding: 0,
    color: "rgb(99, 115, 129)",
    "&.Mui-checked": {
      color: "rgb(0, 167, 111)",
    },
    "&.MuiCheckbox-indeterminate": {
      color: "rgb(0, 167, 111)",
    },
  },
}));

export default useStyles;
