import React from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import AppCard from "./components/AppCard";
import { useSearchParams } from "react-router-dom";
import { AllApps } from "./defaults";
import { makeStyles } from "@material-ui/styles";
import LinearLoading from "../../../../components/LoadingScreen/LinearLoading";

const useStyles = makeStyles((theme) => ({
  allAppsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px 20px",
  },
}));

const AllAdminApps = (props) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const goToSettingsClick = (appType) => {
    setSearchParams({ configureApp: appType });
  };
  return (
    <Box>
      {props.loading ? <LinearLoading /> : null}
      <Box className={classes.allAppsWrapper}>
        {AllApps.filter((app) =>
          props?.allConfiguredApps.find(
            (configuredApp) => app.appType === configuredApp.appType
          )
        )?.map((app, index) => {
          return (
            <AppCard
              appDetails={app}
              onAddAppClick={null}
              goToSettingsClick={goToSettingsClick}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const mapStateToProps = (store) => ({
  allConfiguredApps: store.commonReducer.allConfiguredApps,
  focusedApp: store.commonReducer.focusedApp,
  loading: store.commonReducer.loading,
  error: store.commonReducer.error,
});

export default connect(mapStateToProps)(AllAdminApps);
