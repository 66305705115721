import React, { useState, useEffect, useContext } from "react";

import { Box, Typography, Paper } from "@mui/material";

import ReCAPTCHA from "react-google-recaptcha";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

import { useNavigate, useSearchParams } from "react-router-dom";
import { API_SERVER_URL, useFetch } from "../../utils/urlHelpers";
import useStyles from "./styles";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import { SnackbarContext } from "../../components/SnackbarContext/SnackbarContext";

const MemberSignup = () => {
  const [isValidInvitation, setIsValidInvitation] = useState(false);
  const [invitationDetails, setInvitationDetails] = useState(null);
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [queryParameters] = useSearchParams();
  const [humanKey, setHumanKey] = useState(null);
  const [loading, setLoading] = useState(true);

  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  const classes = useStyles();

  var navigate = useNavigate();
  const run = useFetch;
  useEffect(() => {
    const ab = new AbortController();
    const state = queryParameters.get("state");
    const secret = queryParameters.get("secret");
    const validateInvitationLink = async () => {
      setLoading(true);
      const { data } = await run(
        "post",
        `${API_SERVER_URL}/manage/auth/validateInvitation`,
        {
          secret,
          state,
        },
        { authenticate: false }
      );
      if (data?.message === "success" && data?.data) {
        setInvitationDetails({ ...data?.data });
        setIsValidInvitation(true);
      } else {
        setIsValidInvitation(false);
      }
      setLoading(false);
    };
    if (!state || !secret) {
      setIsValidInvitation(false);
      setLoading(false);
    } else {
      validateInvitationLink();
    }
    return () => ab.abort();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const postableData = {
      state: queryParameters.get("state"),
      secret: queryParameters.get("secret"),
      password,
      name,
      phone_number: phoneNumber,
      humanKey: humanKey,
    };
    setLoading(true);
    const { data, error } = await run(
      "post",
      `${API_SERVER_URL}/manage/auth/acceptInvite`,
      postableData,
      { authenticate: false }
    );
    setLoading(false);
    if (data?.message === "success") {
      showSuccessSnackbar(
        "Successfully accepted the invite! You can now login to your newly created account."
      );
      navigateUser();
    } else if (data?.message) {
      showErrorSnackbar(data.message);
    } else {
      showErrorSnackbar(error || "Something went wrong!");
    }
  };

  const navigateUser = () => setTimeout(() => navigate("/login"), 4000);

  const onHumanKeyChange = (value) => {
    setHumanKey(value);
  };

  const shouldDisableSignup = () => {
    return (
      !invitationDetails?.toUser?.email || !phoneNumber || !name || !password
    );
  };

  return loading ? (
    <LoadingScreen loadingText="Authenticating ...." />
  ) : isValidInvitation && invitationDetails ? (
    <Box className={classes.mainBackground}>
      <Box className={classes.formWrapper}>
        <Paper className={classes.inviteFormWrapper}>
          <Box className={classes.welcomInfo}>
            <img className={classes.logo} src="/brandAssets/logo.svg" alt="" />
            <Typography className={classes.welcomeText}>
              You've Been Invited!
            </Typography>
            <Typography className={classes.welcomeInviteText}>
              Sign Up to accept {invitationDetails.fromUser?.name}'s invitation
              to join Lovely Printer.
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            className={classes.inviteForm}
          >
            <CustomInput
              label="Email Address"
              disabled
              value={invitationDetails.toUser?.email}
              fullWidth
            />
            <Box style={{ display: "flex", gap: 15 }}>
              <CustomInput
                label="Name"
                autoComplete="name"
                fullWidth
                onChange={({ target }) => setName(target.value)}
              />
              <CustomInput
                label="Phone Number"
                type="number"
                onChange={({ target }) => setPhoneNumber(target.value)}
                autoComplete="tel"
                fullWidth
              />
            </Box>
            <CustomInput
              label="Password"
              type="password"
              onChange={({ target }) => setPassword(target.value)}
              autoComplete="current-password"
              fullWidth
            />
            <ReCAPTCHA
              onChange={onHumanKeyChange}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <CustomButton
              inverted={false}
              type="submit"
              fullWidth
              disabled={shouldDisableSignup()}
              sx={{ mb: 2 }}
              label={"Create Account"}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  ) : (
    <Box className={classes.mainBackground}>
      <Box className={classes.formWrapper}>
        <Paper className={classes.inviteFormWrapper}>
          <Box className={classes.welcomInfo}>
            <img className={classes.logo} src="/brandAssets/logo.svg" alt="" />
            <Typography className={classes.welcomeText}>
              Invalid Invite Link!
            </Typography>
            <Typography className={classes.welcomeInviteText}>
              The invite link your trying to access is either invalid or expired
              or accepted.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default MemberSignup;
