import React, { useEffect, useState } from "react";
import HeaderWithBreadcrums from "../../components/HeaderWithBreadcrums/HeaderWithBreadcrums";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import AppsBrowserModal from "./components/AppsBrowserModal";
import AppSettingsBody from "./AppSettingsBody";
import { useSearchParams } from "react-router-dom";
import { fetchAllConfiguredApps, setFocusedApp } from "./redux/common/actions";
import { connect, useDispatch } from "react-redux";
import AllAdminApps from "./AllAdminApps";

const AdminAppsBody = ({
  loading,
  error,
  allConfiguredApps,
  focusedApp,
  setFocusedApp,
}) => {
  const [queryParameters] = useSearchParams();
  const [openAppsBrowser, setOpenAppsBrowser] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const configureExisitingApps = fetchAllConfiguredApps();
    dispatch(configureExisitingApps);
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !error) {
      if (queryParameters.get("configureApp") && allConfiguredApps.length) {
        var activeApp = queryParameters.get("configureApp");
        var foundApp = allConfiguredApps.find((x) => x.appType === activeApp);
        if (foundApp) {
          setFocusedApp(foundApp);
        } else {
          setFocusedApp(null);
        }
      } else {
        setFocusedApp(null);
      }
    }
  }, [queryParameters, allConfiguredApps, setFocusedApp, loading, error]);

  const onAddAppClick = (app) => {
    setOpenAppsBrowser(false);
    setFocusedApp(app);
  };

  return (
    <React.Fragment>
      <HeaderWithBreadcrums
        cta={
          <CustomButton
            label="Add New App"
            onClick={() => setOpenAppsBrowser(!openAppsBrowser)}
            icon={<AddIcon color="inherit" />}
          />
        }
      />

      {focusedApp ? <AppSettingsBody /> : <AllAdminApps />}
      <AppsBrowserModal
        open={openAppsBrowser}
        alreadyConfiguredApps={allConfiguredApps}
        onAddAppClick={onAddAppClick}
        onClose={() => setOpenAppsBrowser(false)}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (store) => ({
  allConfiguredApps: store.commonReducer.allConfiguredApps,
  focusedApp: store.commonReducer.focusedApp,
  loading: store.commonReducer.loading,
  error: store.commonReducer.error,
});
const mapActionsToProps = {
  setFocusedApp: setFocusedApp,
};
export default connect(mapStateToProps, mapActionsToProps)(AdminAppsBody);
