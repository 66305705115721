import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../CustomButton/CustomButton";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 160px)",
  },
  errorImage: {
    width: 350,
    position: "relative",
    zIndex: 5,
  },
  errorInfoWrapper: {
    position: "relative",
    backgroundColor: "#FCDF59",
    border: "2px solid #000",
    padding: "20px 30px",
    marginBottom: 10,
  },
  errorInfoText: {
    fontWeight: 400,
    fontSize: 20,
    color: "#000",
  },
  underlyingDiv: {
    maxWidth: 350,
    position: "relative",
    zIndex: 3,
    left: -40,
    display: "flex",
    flexDirection: "column",
    alignItems: "right",
  },
}));

const ErrorScreen = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainWrapper}>
      <img
        className={classes.errorImage}
        src="/errorImage.png"
        alt="Something went wrong!"
      />
      <Box className={classes.underlyingDiv}>
        <Box className={classes.errorInfoWrapper}>
          <Typography className={classes.errorInfoText}>
            {props.errorText || "Something went wrong!"}
          </Typography>
        </Box>
        <CustomButton label={props.proceedBtnLabel || "Go to dashbaord"} />
      </Box>
    </Box>
  );
};

export default ErrorScreen;
