import React from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import useStyles from "./styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const Carousel = ({ children }) => {
  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <SlickButtonFix>
        <ArrowForwardIosIcon />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <ArrowBackIosIcon />
      </SlickButtonFix>
    ),
  };
  return (
    <Box className={classes.carouselWrapper}>
      <Slider {...settings}>{children}</Slider>
    </Box>
  );
};

export default Carousel;
