import React, { useContext, useRef, useState } from "react";

import { Box, Paper } from "@mui/material";

import ReCAPTCHA from "react-google-recaptcha";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  setAccessToken,
  setUserDetails,
} from "../../redux/userReducer/actions";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API_SERVER_URL, useFetch } from "../../utils/urlHelpers";
import { SnackbarContext } from "../../components/SnackbarContext/SnackbarContext";
import CustomButton from "../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import CustomInput from "../../components/CustomInput/CustomInput";

const Login = (props) => {
  const [humanKey, setHumanKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  var location = useLocation();
  var navigate = useNavigate();
  const classes = useStyles();
  const recaptchaRef = useRef();
  const run = useFetch;

  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const postableData = {
      email,
      password,
      humanKey,
    };
    setLoading(true);
    const { data, error } = await run(
      "POST",
      `${API_SERVER_URL}/manage/auth/login`,
      postableData
    );
    if (data?.message === "success") {
      setUserAndNavigate(data?.user, data?.accessToken);
      showSuccessSnackbar("Successfully logged in!");
    } else if (error) {
      showErrorSnackbar(error);
    } else {
      showErrorSnackbar("Something went wrong!");
    }
    resetReCaptcha();
    setLoading(false);
  };

  const setUserAndNavigate = (user, accessToken) => {
    props.setUserDetails(user);
    props.setAccessToken(accessToken);
    var callbackUrl = new URLSearchParams(location.search).get("callback");
    if (!callbackUrl || callbackUrl === "/") {
      callbackUrl = "/dashboard";
    }
    setTimeout(() => navigate(callbackUrl), 500);
  };

  const shouldDisableSignin = () => {
    return !email || !password || !humanKey;
  };

  const resetReCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <>
      <Box className={classes.mainFormWrapper}>
        <Paper className={classes.formWrapper}>
          <Box className={classes.branding}>
            <img src="/brandAssets/logo.svg" alt="" className={classes.logo} />
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            className={classes.actualForm}
          >
            <CustomInput
              fullWidth
              label="Email Address"
              onChange={({ target }) => setEmail(target.value)}
              autoComplete="email"
              autoFocus
            />
            <CustomInput
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={({ target }) => setPassword(target.value)}
            />
            <ReCAPTCHA
              onChange={(value) => setHumanKey(value)}
              ref={recaptchaRef}
              onExpired={resetReCaptcha}
              onErrored={resetReCaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <CustomButton
              type="submit"
              disabled={shouldDisableSignin()}
              fullWidth
              variant="contained"
              sx={{ mb: 2 }}
              label="Sign In"
            />
          </Box>
        </Paper>
      </Box>
      {loading ? <LoadingScreen loadingText="Authenticating ...." /> : null}
    </>
  );
};

var mapStateToProps = (store) => ({
  ...store.userReducer,
});
var mapActionsToProps = {
  setUserDetails: setUserDetails,
  setAccessToken: setAccessToken,
};
export default connect(mapStateToProps, mapActionsToProps)(Login);
