import { ReactComponent as ProductsIcon } from "../../svgAssets/ic_product.svg";
import { ReactComponent as OrdersIcon } from "../../svgAssets/ic_order.svg";
import { ReactComponent as UsersIcon } from "../../svgAssets/ic_user.svg";
import { ReactComponent as LockIcon } from "../../svgAssets/ic_lock.svg";
import { AdminRoles } from "../../utils/constants";
// import { ReactComponent as AnalyticsIcon } from "../../svgAssets/ic_analytics.svg";

const accessibleManagementLinksForProductManager = [
  {
    label: "Products",
    icon: ProductsIcon,
    link: `${process.env.REACT_APP_DASHBOARD_PATH}/products`,
  },
  {
    label: "Categories",
    icon: ProductsIcon,
    link: `${process.env.REACT_APP_DASHBOARD_PATH}/categories`,
  },
];

const accessibleManagementLinksForOrderManager = [
  {
    label: "Orders",
    icon: OrdersIcon,
    link: `${process.env.REACT_APP_DASHBOARD_PATH}/orders`,
  },
];

const accessibleManagementLinksForAdmin = [
  ...accessibleManagementLinksForProductManager,
  ...accessibleManagementLinksForOrderManager,
];

const accessibleManagementLinksForSuperAdmin = [
  {
    label: "Members",
    icon: UsersIcon,
    link: `${process.env.REACT_APP_DASHBOARD_PATH}/members`,
  },
  ...accessibleManagementLinksForProductManager,
  ...accessibleManagementLinksForOrderManager,
  {
    label: "Apps",
    icon: LockIcon,
    link: `${process.env.REACT_APP_DASHBOARD_PATH}/adminApps`,
  },
];

const getAllowedManagementNavLinks = (scope) => {
  switch (scope) {
    case AdminRoles.orderManager:
      return accessibleManagementLinksForOrderManager;
    case AdminRoles.productManager:
      return accessibleManagementLinksForProductManager;
    case AdminRoles.admin:
      return accessibleManagementLinksForAdmin;
    case AdminRoles.superAdmin:
      return accessibleManagementLinksForSuperAdmin;
    default:
      return [];
  }
};

export { getAllowedManagementNavLinks };
