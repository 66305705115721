import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    padding: "0 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    marginBottom: "25px",
    "@media (max-width: 900)": {
      padding: "0 24px",
    },
  },
  headerTitle: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    "@media (max-width: 600px)": {
      fontSize: "18 !important",
    },
    color: "rgb(33, 43, 54) !important",
  },
  bradcrumsLink: {
    textDecoration: "none",
    textTransform: "capitalize",
    fontSize: 14,
    color: "rgb(33, 43, 54) !important",
  },
  bradcrumsLinkInactive: {
    color: "rgb(99, 115, 129) !important",
    cursor: "not-allowed",
  },
  breadcrumsSeparator: {
    width: "4px !important",
    height: "4px !important",
    margin: "0 12px",
    borderRadius: "50% !important",
    backgroundColor: "rgb(145, 158, 171)",
    "@media (max-width: 900px)": {
      margin: 0,
    },
  },
}));

export default useStyles;
