import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  bulkEditBtn: {
    padding: 0,
    fontSize: 18,
    color: "rgb(0, 167, 111)",
  },
  bulkEditText: {
    fontSize: 14,
    color: "rgb(0, 167, 111)",
  },
  checkbox: {
    padding: 0,
    color: "rgb(99, 115, 129)",
    "&.Mui-checked": {
      color: "rgb(0, 167, 111)",
    },
  },
  productNameContainer: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  productImage: {
    height: 64,
    width: 64,
    borderRadius: 12,
  },
  productName: {
    textDecoration: "none !important",
    fontSize: "14px !important",
    color: "rgb(33, 43, 54) !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    fontWeight: "500 !important",
    whiteSpace: "nowrap !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  productReligion: {
    color: "rgb(145, 158, 171) !important",
    fontSize: "14px !important",
    fontWeight: "300 !important",
  },
  lightHeadline: {
    fontSize: "13px !important",
    color: "rgb(33, 43, 54) !important",
    fontWeight: "300 !important",
  },
  dullDescription: {
    fontSize: "13px !important",
    color: "rgb(145, 158, 171) !important",
    fontWeight: "300 !important",
  },
  productStockAvailable: {
    color: "rgb(34, 197, 94) !important",
  },
  productStockUnavailable: {
    color: "rgb(255, 171, 0) !important",
  },
  chipActive: {
    backgroundColor: "rgba(0, 184, 217, 0.16) !important",
    color: "rgb(0, 108, 156) !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    padding: "0px 6px",
    width: "max-content",
    borderRadius: 6,
    textTransform: "capitalize",
  },
  chipInactive: {
    backgroundColor: "rgba(145, 158, 171, 0.16)",
    color: "rgb(99, 115, 129) !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    padding: "0px 6px",
    width: "max-content",
    borderRadius: 6,
    textTransform: "capitalize",
  },
  newProductCta: {
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    gap: 4,
    textTransform: "none",
    backgroundColor: "rgb(33, 43, 54)",
    "&:hover": {
      backgroundColor: "rgb(69, 79, 91)",
    },
  },
  moreOptionBtn: {
    color: "rgb(99, 115, 129) !important",
  },
}));

export default useStyles;
