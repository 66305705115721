export const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],
  [{ direction: "rtl" }, { align: [] }], //text direction
  ["link", "clean"], // link
];

export const stockOptions = [
  { label: "In stock", value: true },
  { label: "Out of stock", value: false },
];

export const getCategoriesOptions = (allCategories) => {
  return allCategories.map((category) => ({
    label: category.categoryName,
    value: category._id,
  }));
};

export const defaultEmptyProduct = {
  _id: "",
  productId: "",
  productName: "",
  refNumber: "",
  categoryId: "",
  categoryName: "",
  showPlainPrintedTags: true,
  allowEnquiry: true,
  imageUrls: [],
  weight: 10,
  inStock: true,
  state: "draft",
  availableOptions: [
    {
      variantName: "plain",
      isAvailable: true,
      price: 100,
      discountedPrice: null,
      MOQ: 1,
      QM: 1,
    },
    {
      variantName: "printed",
      isAvailable: true,
      price: 150,
      discountedPrice: null,
      MOQ: 1,
      QM: 1,
    },
  ],
  shortDescription: "",
  additionalInformation: "",
};
