const setUserInLS = (user) => {
  if (user && typeof user === "object") {
    localStorage.setItem("user.name", user?.name);
    localStorage.setItem("user.email", user?.email);
    localStorage.setItem("user.phone_number", user?.phone_number);
    localStorage.setItem("user.role", user?.role);
    localStorage.setItem("user.imageUrl", user?.imageUrl);
  }
};
const getUserFromLS = () => {
  let name = localStorage.getItem("user.name");
  let email = localStorage.getItem("user.email");
  let phone_number = localStorage.getItem("user.phone_number");
  let imageUrl = localStorage.getItem("user.imageUrl");
  let role = localStorage.getItem("user.role");
  if (!name || !email || !role) {
    return null;
  }
  return {
    name,
    email,
    phone_number,
    role,
    imageUrl,
  };
};
const setAccessTokenInLS = (token) => {
  localStorage.setItem("user.accessToken", token);
};
const getAccessTokenFromLS = () => {
  let accessToken = localStorage.getItem("user.accessToken");
  if (!accessToken) return null;
  return accessToken;
};

export { setUserInLS, getUserFromLS, setAccessTokenInLS, getAccessTokenFromLS };
