import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainFormWrapper: {
    backgroundColor: "rgb(33, 43, 54)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
  formWrapper: {
    padding: "20px 30px",
  },
  branding: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    marginBottom: 20,
  },
  actualForm: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    minWidth: 400,
  },
  logo: {
    width: 100,
  },
}));
export default useStyles;
