import LAYOUT_ACTIONS from "./actionTypes";

const setMobileLayoutOptions = (options) => {
  return {
    type: LAYOUT_ACTIONS.CHANGE_MOBILE_LAYOUT,
    payload: options,
  };
};
const setDesktopLayoutOptions = (options) => {
  return {
    type: LAYOUT_ACTIONS.CHANGE_DESKTOP_LAYOUT,
    payload: options,
  };
};

export { setMobileLayoutOptions, setDesktopLayoutOptions };
