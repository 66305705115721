const MEMBER_ACTIONS = {
  SET_MEMBERS_LIST: "SET_MEMBERS_LIST",
  SET_MEMBERS_LOADING: "SET_MEMBERS_LOADING",
  SET_MEMBERS_FETCH_ERROR: "SET_MEMBERS_FETCH_ERROR",
  TOGGLE_MEMBER_MODAL: "TOGGLE_MEMBER_MODAL",
  SET_FOCUSED_MEMBER: "SET_FOCUSED_MEMBER",
  REFRESH_MEMBERS_LIST: "REFRESH_MEMBERS_LIST",
  SET_FOCUSED_TAB: "SET_FOCUSED_TAB",
  SET_INVITES_LIST: "SET_INVITES_LIST",
  SET_INVITES_LOADING: "SET_INVITES_LOADING",
  SET_INVITTAIONS_FETCH_ERROR: "SET_INVITTAIONS_FETCH_ERROR",
  SET_FOCUSED_INVITE: "SET_FOCUSED_INVITE",
  SET_LOAD_MEMBERS: "SET_LOAD_MEMBERS",
  SET_LOAD_INVITATIONS: "SET_LOAD_INVITATIONS",
  REFRESH_INVITES_LIST: "REFRESH_INVITES_LIST",
};
export default MEMBER_ACTIONS;
