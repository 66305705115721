import { makeStyles } from "@material-ui/styles";
import { TextField } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  basicTextField: {
    "& label": {
      fontSize: 14,
      fontWeight: 300,
      color: "rgb(145, 158, 171)",
    },
    "& label.Mui-focused": {
      fontWeight: 600,
      color: "rgb(33, 43, 54)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& input": {
        fontSize: "14px !important",
        fontWeight: "300 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(33, 43, 54)",
      },
    },
  },
}));

const CustomInput = (props) => {
  const classes = useStyles();
  return <TextField className={classes.basicTextField} {...props} />;
};

export default CustomInput;
