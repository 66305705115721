import USER_ACTIONS from "./actionTypes";

const setUserDetails = (user) => {
  return {
    type: USER_ACTIONS.SET_USER,
    payload: user,
  };
};
const setUserLoading = (loadingState) => {
  return {
    type: USER_ACTIONS.SET_LOADING,
    payload: loadingState,
  };
};

const setAccessToken = (token) => {
  return {
    type: USER_ACTIONS.SET_ACCESS_TOKEN,
    payload: token,
  };
};

const clearUser = () => {
  return {
    type: USER_ACTIONS.CLEAR_USER,
  };
};

export { setUserDetails, setUserLoading, setAccessToken, clearUser };
