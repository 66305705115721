import React, { useContext, useEffect, useState } from "react";
import { CustomTable } from "../../../../components/CTable/CustomTables";
import CustomTableHeader from "../../../../components/CTable/components/CustomTableHeader";
import {
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
} from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AddIcon from "@mui/icons-material/Add";

import useStyles from "./styles";
import { getCategoryPreviewPageUrl, tableHeaderOptions } from "./defaults";
import { Link } from "react-router-dom";
import {
  getDateStringFromTimeStamp,
  getTimeStringFromTimeStamp,
} from "../../../../utils/commonHelpers";
import HeaderWithBreadcrums from "../../components/HeaderWithBreadcrums/HeaderWithBreadcrums";
import { connect } from "react-redux";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {
  API_SERVER_URL,
  getUrlWithSearchParams,
  useAsyncFetchWithToken,
} from "../../../../utils/urlHelpers";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { SnackbarContext } from "../../../../components/SnackbarContext/SnackbarContext";
import * as CATEGORY_ACTIONS from "../../../../redux/categoriesReducer/action";

const ColumnsCount = 8;

const Categories = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [productOptionAnchor, setProductOptionAnchor] = useState(null);
  const [searchDetails, setSearchDetails] = useState({});
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { run } = useAsyncFetchWithToken();
  useEffect(() => {
    const ac = new AbortController();
    var params = {
      ...props.pageDetails,
      ...searchDetails,
    };
    var urlToHit = getUrlWithSearchParams(
      `${API_SERVER_URL}/manage/category`,
      params
    );
    const fetchCategories = async () => {
      props.setCategoriesLoading(true);
      const { data, error } = await run("GET", urlToHit);
      if (data && !error) {
        props.setCategoriesList([...data?.data]);
        props.setPageDetails({
          totalPages: data?.totalPages,
          totalResults: data?.totalResults,
          page: data?.currentPage,
          limit: data?.limit,
        });
      }
      props.setFetchError(error);
      props.setCategoriesLoading(false);
    };
    fetchCategories();
    return () => ac.abort();
  }, [
    props.pageDetails.page,
    props.pageDetails.limit,
    searchDetails,
    shouldRefresh,
  ]);

  useEffect(() => {
    if (!props.categoriesLoading && props.error) {
      showErrorSnackbar(
        props.error?.response?.data?.message || "Something went wrong!"
      );
    }
  }, [props.error, props.categoriesLoading]);

  const onSelectAllClick = () => {
    var allCategoryIds = props.categoriesList.map((x) => x._id);
    if (
      selectedCategoryIds.length === props.categoriesList.length ||
      selectedCategoryIds.length > 0
    ) {
      setSelectedCategoryIds([]);
    } else {
      setSelectedCategoryIds(allCategoryIds);
    }
  };
  const onCategorySelect = (productId) => {
    var atIndex = selectedCategoryIds.indexOf(productId);
    if (atIndex > -1) {
      selectedCategoryIds.splice(atIndex, 1);
      setSelectedCategoryIds([...selectedCategoryIds]);
    } else {
      selectedCategoryIds.push(productId);
      setSelectedCategoryIds([...selectedCategoryIds]);
    }
  };
  const tableHeader = (
    <CustomTableHeader
      haveBulkOptions={selectedCategoryIds.length}
      haveCheckbox={true}
      bulkOperation={
        <React.Fragment>
          <TableCell size="small" colSpan={ColumnsCount - 2}>
            <Typography className={classes.bulkEditText}>
              {selectedCategoryIds.length} Selected
            </Typography>
          </TableCell>
          <TableCell size="small">
            <IconButton
              className={classes.bulkEditBtn}
              onClick={() => setShowDeleteModal(true)}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </React.Fragment>
      }
      checkboxChecked={
        selectedCategoryIds.length > 0 &&
        selectedCategoryIds.length === props.categoriesList.length
      }
      checkboxIndeterminate={
        selectedCategoryIds.length > 0 &&
        selectedCategoryIds.length !== props.categoriesList.length
      }
      headerOptions={tableHeaderOptions}
      onBulkSelect={onSelectAllClick}
    />
  );
  const onMenuOpenClick = (e, product) => {
    setProductOptionAnchor(e.currentTarget);
    props.setFocusedCategory(product);
  };
  const onMenuCloseClick = () => {
    props.setFocusedCategory(null);
    setProductOptionAnchor(null);
  };
  const onProductEditClick = () => {
    setProductOptionAnchor(null);
    props.toggleCategorySidetray();
  };
  const onProductDeleteClick = () => {
    setShowDeleteModal(true);
    setProductOptionAnchor(null);
  };
  const onConfirmDelete = async () => {
    setShowDeleteModal(false);
    var categoryIds = [];
    if (selectedCategoryIds?.length > 0) {
      categoryIds = selectedCategoryIds;
    } else if (props.focusedCategoryDetails) {
      categoryIds = [props.focusedCategoryDetails._id];
    }
    const { data, error } = await run(
      "DELETE",
      `${API_SERVER_URL}/manage/category`,
      { categoryIds }
    );
    if (data && !error) {
      showSuccessSnackbar(
        `Successfully deleted ${data?.data?.deleteCount} categories!`
      );
      setSelectedCategoryIds([]);
      props.setFocusedCategory(null);
      setShouldRefresh(!shouldRefresh);
    }
  };
  return (
    <React.Fragment>
      <HeaderWithBreadcrums
        cta={
          <CustomButton
            label="New Category"
            onClick={props.toggleCategorySidetray}
            icon={<AddIcon color="inherit" />}
          />
        }
      />
      <CustomTable
        tableHeader={tableHeader}
        loading={props.categoriesLoading}
        tableBody={
          <TableBody>
            {props.categoriesList
              .slice(0, props.pageDetails.limit)
              .map((category, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      <Checkbox
                        className={classes.checkbox}
                        onChange={() => onCategorySelect(category._id)}
                        checked={selectedCategoryIds.includes(category._id)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className={classes.productNameContainer}>
                        <img
                          className={classes.productImage}
                          src={category.imageUrl ?? ""}
                          alt="category"
                        />
                        <Box>
                          <Link
                            to={getCategoryPreviewPageUrl(category)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.productName}
                          >
                            {category.categoryName}
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.description}>
                        {category.shortDescription}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.lightHeadline}>
                        {getDateStringFromTimeStamp(category.createdAt)}
                      </Typography>
                      <Typography className={classes.dullDescription}>
                        {getTimeStringFromTimeStamp(category.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        className={
                          category.state === "published"
                            ? classes.chipActive
                            : classes.chipInactive
                        }
                      >
                        {category.state}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.moreOptionBtn}>
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={(e) => onMenuOpenClick(e, category)}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 200]}
                count={props.pageDetails.totalResults}
                onPageChange={(_, page) =>
                  props.setPageDetails({ ...props.pageDetails, page })
                }
                onRowsPerPageChange={({ target }) =>
                  props.setPageDetails({
                    ...props.pageDetails,
                    limit: target.value,
                    page: 0,
                  })
                }
                page={props.pageDetails.page}
                rowsPerPage={props.pageDetails.limit}
              />
            </TableRow>
          </TableBody>
        }
      />
      <Menu
        anchorEl={productOptionAnchor}
        open={Boolean(productOptionAnchor)}
        onClose={onMenuCloseClick}
      >
        <MenuItem onClick={onProductEditClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <BorderColorOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={onProductDeleteClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteModal}
        description={`This will delete ${
          selectedCategoryIds.length ? selectedCategoryIds.length : 1
        } categories. Are you sure you want to proceed?`}
        onClose={() => setShowDeleteModal(false)}
        onCancelClick={() => setShowDeleteModal(false)}
        onConfirmClick={onConfirmDelete}
      />
    </React.Fragment>
  );
};
const mapStoreToProps = (store) => ({
  ...store.categoriesReducer,
});
const mapActionsToProps = {
  setPageDetails: CATEGORY_ACTIONS.setPageDetails,
  setFetchError: CATEGORY_ACTIONS.setFetchError,
  setCategoriesList: CATEGORY_ACTIONS.setCategoriesList,
  setCategoriesLoading: CATEGORY_ACTIONS.setCategoriesLoading,
  toggleCategorySidetray: CATEGORY_ACTIONS.toggleCategorySidetray,
  setFocusedCategory: CATEGORY_ACTIONS.setFocusedCategory,
};
export default connect(mapStoreToProps, mapActionsToProps)(Categories);
