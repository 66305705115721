import React from "react";
import { Box, LinearProgress } from "@mui/material";

const LinearLoading = () => {
  return (
    <Box style={{ color: "rgb(0, 167, 111)", margin: "10px 0" }}>
      <LinearProgress color="inherit" />
    </Box>
  );
};

export default LinearLoading;
