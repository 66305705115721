import { API_SERVER_URL, useFetch } from "../../../../../../utils/urlHelpers";
import { AppEndPoints, AppTypes } from "../../defaults";
import GMC_ACTION_TYPES from "./actionTypes";

export const setAppLoading = (loadingState) => {
  return {
    type: GMC_ACTION_TYPES.SET_LOADING,
    payload: loadingState,
  };
};
export const setAppError = (err) => {
  return {
    type: GMC_ACTION_TYPES.SET_APP_ERROR,
    payload: err,
  };
};
export const setAppLoginLink = (loginLink) => {
  return {
    type: GMC_ACTION_TYPES.SET_APP_LOGIN_LINK,
    payload: loginLink,
  };
};
export const setAvailableMerchantIds = (merchantIds) => {
  return {
    type: GMC_ACTION_TYPES.SET_AVAILABLE_MERCHANT_IDS,
    payload: [...merchantIds],
  };
};

// thunk actions
export const initGoogleMerchantCenter = () => {
  return async (dispatch, getState) => {
    dispatch(setAppLoading(true));
    const { data: merchantIds, error: merchantIdsError } = await useFetch(
      "GET",
      `${API_SERVER_URL}/manage/${
        AppEndPoints[AppTypes.GoogleMerchantCenterContentApi]
      }/availableMerchantIds`,
      null,
      { authenticate: true }
    );
    if (merchantIds && !merchantIdsError) {
      dispatch(setAvailableMerchantIds(merchantIds?.data));
    }
    const { data: loginLink, error: loginLinkError } = await useFetch(
      "GET",
      `${API_SERVER_URL}/manage/${
        AppEndPoints[AppTypes.GoogleMerchantCenterContentApi]
      }/loginUrl`,
      null,
      { authenticate: true }
    );
    if (loginLink && !loginLinkError) {
      dispatch(setAppLoginLink(loginLink?.data ?? null));
    }
    dispatch(
      setAppError(
        loginLinkError
          ? loginLinkError
          : merchantIdsError
          ? merchantIdsError
          : null
      )
    );
    dispatch(setAppLoading(false));
  };
};
