import { API_SERVER_URL, useFetch } from "../../../../../../utils/urlHelpers";
import { AppEndPoints, AppTypes } from "../../defaults";
import { setAppLoading as setGMCLoading } from "../googleMerhcantCenter/actions";
import COMMON_ACTIONS from "./actionTypes";

export const setLoading = (loadingState) => {
  return {
    type: COMMON_ACTIONS.SET_LOADING,
    payload: loadingState,
  };
};
export const setAllConfiguredApps = (apps) => {
  return {
    type: COMMON_ACTIONS.SET_ALL_CONFIGURED_APPS,
    payload: apps,
  };
};
export const setFocusedApp = (app) => {
  return {
    type: COMMON_ACTIONS.SET_FOCUSED_APP,
    payload: app,
  };
};
export const setDataError = (err) => {
  return {
    type: COMMON_ACTIONS.SET_ERROR,
    payload: err,
  };
};

// thunk functions

export const fetchAllConfiguredApps = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { data, error } = await useFetch(
      "GET",
      `${API_SERVER_URL}/manage/${AppEndPoints[AppTypes.AllConfiguredApps]}`,
      null,
      { authenticate: true }
    );
    dispatch(setAllConfiguredApps([...data?.data]));
    dispatch(setDataError(error));
    dispatch(setLoading(false));
  };
};

export const updateAppSettings = (appType, newSettings) => {
  return async (dispatch, getState) => {
    let appLoading = () => {};
    switch (appType) {
      case AppTypes.GoogleMerchantCenterContentApi:
        appLoading = setGMCLoading;
        break;
      default:
        appLoading = () => {};
    }
    dispatch(appLoading(true));
    const { data, error } = await useFetch(
      "PUT",
      `${API_SERVER_URL}/manage/${AppEndPoints[appType]}/updateSettings`,
      {
        appSettings: newSettings,
      },
      { authenticate: true }
    );
    dispatch(appLoading(false));
    dispatch(setDataError(error));
    if (data?.data === "success") {
      dispatch(fetchAllConfiguredApps());
    }
  };
};
