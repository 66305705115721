import GMC_ACTION_TYPES from "./actionTypes";

const initialState = {
  loading: true,
  error: null,
  availableMerchantIds: [],
  appLoginLink: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GMC_ACTION_TYPES.SET_LOADING:
      return { ...state, loading: action.payload };
    case GMC_ACTION_TYPES.SET_APP_ERROR:
      return { ...state, error: action.payload };
    case GMC_ACTION_TYPES.SET_AVAILABLE_MERCHANT_IDS:
      return { ...state, availableMerchantIds: action.payload };
    case GMC_ACTION_TYPES.SET_APP_LOGIN_LINK:
      return { ...state, appLoginLink: action.payload };
    default:
      return state;
  }
};

export default reducer;
