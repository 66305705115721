import { makeStyles } from "@material-ui/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  basicSelect: {
    "& .MuiOutlinedInput-input": {
      fontWeight: 300,
      fontSize: 14,
      color: "rgb(33, 43, 54)",
    },
    "& label": {
      fontSize: 14,
      fontWeight: 300,
      color: "rgb(145, 158, 171)",
    },
    "& label.Mui-focused": {
      fontWeight: 600,
      color: "rgb(33, 43, 54)",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& input": {
        fontSize: "14px !important",
        fontWeight: "300 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(33, 43, 54)",
      },
    },
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.basicSelect}>
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.selection}
        label={props.label}
        onChange={props.onSelectionChange}
      >
        {props.options.map((option, idx) => (
          <MenuItem
            disabled={option?.disabled || false}
            key={idx}
            value={option?.value}
          >
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
