import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const useStyles = makeStyles((theme) => ({
  mainAppContainer: {
    padding: 20,
    border: "1px solid rgb(145, 158, 171)",
    borderRadius: 10,
    height: "max-content",
    width: 200,
  },
  appNameAndIcon: {
    display: "flex",
    gap: 10,
  },
  appName: {
    fontWeight: 400,
    fontSize: 14,
    color: "rgb(33, 43, 54)",
  },
  appDescription: {
    marginTop: 10,
    fontWeight: 300,
    fontSize: 13,
    color: "rgb(33, 43, 54)",
  },
  appIcon: {
    width: 40,
  },
}));
const AppCard = ({ appDetails, onAddAppClick, goToSettingsClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainAppContainer}>
      <Box className={classes.appNameAndIcon}>
        <img src={appDetails.appIcon} alt="" className={classes.appIcon} />
        <Box>
          <Typography className={classes.appName}>
            {appDetails.appName}
          </Typography>
        </Box>
      </Box>
      <Typography paragraph className={classes.appDescription}>
        {appDetails.appDescription}
      </Typography>
      <Box style={{ display: "flex", gap: 10 }}>
        <CustomButton
          tooltipText={
            appDetails.isConfigured ? "App already configured." : null
          }
          disabled={appDetails.isConfigured}
          label="Add App"
          sx={{ width: "100% !important" }}
          onClick={onAddAppClick}
          inverted={appDetails.isConfigured}
        />
        {appDetails.isConfigured ? (
          <CustomButton
            icon={<SettingsOutlinedIcon />}
            sx={{ width: "100% !important" }}
            onClick={() => goToSettingsClick(appDetails.appType)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AppCard;
