import COMMON_ACTIONS from "./actionTypes";

const initialState = {
  loading: true,
  error: null,
  allConfiguredApps: [],
  focusedApp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.SET_ALL_CONFIGURED_APPS:
      return { ...state, allConfiguredApps: action.payload };
    case COMMON_ACTIONS.SET_ERROR:
      return { ...state, error: action.payload };
    case COMMON_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case COMMON_ACTIONS.SET_FOCUSED_APP:
      return { ...state, focusedApp: action.payload };
    default:
      return state;
  }
};

export default reducer;
