import { readableAdminRoles } from "../../../../utils/commonHelpers";
import { AdminRoles } from "../../../../utils/constants";

export const tableHeaderOptions = [
  {
    type: "text",
    label: "Avatar",
  },
  {
    type: "text",
    label: "Details",
  },
  {
    type: "text",
    label: "Phone Number",
  },
  {
    type: "text",
    label: "Joined at",
  },
  {
    type: "text",
    label: "Role",
  },
  {
    type: "blank",
    label: "",
  },
];
export const invitesTableHeaderOptions = [
  {
    type: "text",
    label: "Email",
  },
  {
    type: "text",
    label: "Invited By",
  },
  {
    type: "text",
    label: "Invited on",
  },
  {
    type: "text",
    label: "Role",
  },
  {
    type: "blank",
    label: "",
  },
];

export const defaultEmptyMember = {
  email: "",
  role: "",
  _id: null,
  phone_number: "",
};

export const roleOptions = Object.keys(AdminRoles).map((x) => {
  if (AdminRoles[x] === AdminRoles.superAdmin) {
    return {
      label: readableAdminRoles(AdminRoles[x]),
      value: AdminRoles[x],
      disabled: true,
    };
  }
  return { label: readableAdminRoles(AdminRoles[x]), value: AdminRoles[x] };
});
