import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100vw",
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  rightComponent: {
    flex: 1,
    overflowY: "scroll",
  },
  leftComponent: {
    overflowY: "scroll",
    borderRight: "1px dashed rgba(145, 158, 171, 0.2)",
    "@media (min-width: 1080px)": {
      display: "block",
    },
    "@media (max-width: 1080px)": {
      display: "none",
    },
  },
  responsiveHeader: {
    "@media (max-width: 1080px)": {
      width: "100vw !important",
    },
  },
  protectedRouteBody: {
    padding: "0 40px",
    "@media (max-width: 900)": {
      padding: "0 24px",
    },
  },
}));

export default useStyles;
