import React from "react";
import { AppTypes } from "./defaults";
import GoogleMerhcantCenter from "./Apps/GoogleMerchantCenter/GoogleMerhcantCenter";
import { connect } from "react-redux";

const AppSettingsBody = (props) => {
  switch (props.focusedApp.appType) {
    case AppTypes.GoogleMerchantCenterContentApi:
      return <GoogleMerhcantCenter appDetails={props.focusedApp} />;
    default:
      return null;
  }
};

const mapStateToProps = (store) => ({
  allConfiguredApps: store.commonReducer.allConfiguredApps,
  focusedApp: store.commonReducer.focusedApp,
  loading: store.commonReducer.loading,
  error: store.commonReducer.error,
});
export default connect(mapStateToProps)(AppSettingsBody);
