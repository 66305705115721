import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyToken } from "../../utils/urlHelpers";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  clearUser,
  setUserDetails,
  setUserLoading,
} from "../../redux/userReducer/actions";
import { connect } from "react-redux";

const ProtectedRoute = ({ children, isUserLoading, accessToken, ...props }) => {
  const [isAutenticated, setIsAutenticated] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  useLayoutEffect(() => {
    const ac = new AbortController();
    const checkForLoggedInState = async () => {
      if (accessToken) {
        try {
          let response = await verifyToken();
          if (response?.message === "success") {
            props.setUserDetails(response?.data);
            setIsAutenticated(true);
          } else {
            props.clearUser();
            setIsAutenticated(false);
          }
        } catch (err) {
          // need to log this error
          props.clearUser();
          setIsAutenticated(false);
        }
      }
      props.setUserLoading(false);
    };
    checkForLoggedInState();
    return () => ac.abort();
  }, []);

  if (!accessToken && !isUserLoading) {
    navigate(`/login?callback=${encodeURI(location.pathname)}`);
    return;
  }
  return isUserLoading ? (
    <LoadingScreen loadingText="Authenticating ..." />
  ) : isAutenticated ? (
    children
  ) : (
    <div>Error</div>
  );
};

const mapStateToProps = (store) => ({
  user: store.userReducer.user,
  isUserLoading: store.userReducer.isUserLoading,
  accessToken: store.userReducer.accessToken,
});

const mapActionsToProps = {
  setUserDetails: setUserDetails,
  setUserLoading: setUserLoading,
  clearUser: clearUser,
};

export default connect(mapStateToProps, mapActionsToProps)(ProtectedRoute);
