import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CustomButton from "../CustomButton/CustomButton";

const ConfirmationDialog = (props) => {
  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth onClose={props.onClose}>
      <DialogTitle style={{ paddingBottom: 5 }}>
        {props.title || "Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.description ||
            "Are you sure that you really want to do this.?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingRight: 25, paddingBottom: 15 }}>
        <CustomButton
          label={props.cancelLabel ?? "Cancel"}
          onClick={props.onCancelClick}
          inverted={true}
        />
        <CustomButton
          label={props.confirmLabel ?? "Confirm"}
          onClick={props.onConfirmClick}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
