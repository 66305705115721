import { Paper, Table, TableContainer } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import CustomTableLoading from "./components/CustomTableLoading";

const CustomTable = ({ tableHeader, tableBody, loading, loadingText }) => {
  const classes = useStyles();
  if (loading) {
    return <CustomTableLoading loadingText={loadingText} />;
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={classes.table}>
        {tableHeader}
        {tableBody}
      </Table>
    </TableContainer>
  );
};

export { CustomTable };
