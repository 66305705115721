import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableRow,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CustomTable } from "../../../../../components/CTable/CustomTables";
import CustomTableHeader from "../../../../../components/CTable/components/CustomTableHeader";
import { IconButton, TableCell, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import {
  getDateStringFromTimeStamp,
  getTimeStringFromTimeStamp,
  readableAdminRoles,
} from "../../../../../utils/commonHelpers";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/ConfirmationDialog";
import {
  API_SERVER_URL,
  useAsyncFetchWithToken,
} from "../../../../../utils/urlHelpers";
import { SnackbarContext } from "../../../../../components/SnackbarContext/SnackbarContext";
import { connect } from "react-redux";
import useStyles from "../styles";
import { invitesTableHeaderOptions } from "../defaults";
import * as MemberActions from "../../../../../redux/membersReducer/action";

const ColumnsCount = 6;

const PendingInvitations = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const [invitesOptionAnchor, setInvitesOptionAnchor] = useState(null);
  const [selectedInvitesIds, setSelectedInvitesIds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { error, loading, run } = useAsyncFetchWithToken();
  useEffect(() => {
    const ac = new AbortController();
    if (props.loadInvites || props.refreshInvites) {
      var urlToHit = `${API_SERVER_URL}/manage/members/pendingInvites`;
      const fetchInvites = async () => {
        props.setInvitesLoading(true);
        const { data, error } = await run("GET", urlToHit);
        props.setInvitesLoading(false);
        if (data && !error) {
          props.setInvitationsList([...data?.data]);
          props.setLoadInvitations(false);
        }
      };
      fetchInvites();
    }
    return () => ac.abort();
  }, [props.refreshInvites, props.loadInvites]);

  useEffect(() => {
    if (!loading && error) {
      showErrorSnackbar(error || "Something went wrong!");
    }
  }, [error, loading]);
  const onSelectAllClick = () => {
    var allMembersIds = props.invitationsList.map((x) => x._id);
    if (
      selectedInvitesIds.length === props.invitationsList.length ||
      selectedInvitesIds.length > 0
    ) {
      setSelectedInvitesIds([]);
    } else {
      setSelectedInvitesIds(allMembersIds);
    }
  };
  const onInviteSelect = (memberId) => {
    var atIndex = selectedInvitesIds.indexOf(memberId);
    if (atIndex > -1) {
      selectedInvitesIds.splice(atIndex, 1);
      setSelectedInvitesIds([...selectedInvitesIds]);
    } else {
      selectedInvitesIds.push(memberId);
      setSelectedInvitesIds([...selectedInvitesIds]);
    }
  };
  const tableHeader = (
    <CustomTableHeader
      haveBulkOptions={selectedInvitesIds.length}
      haveCheckbox={true}
      bulkOperation={
        <React.Fragment>
          <TableCell size="small" colSpan={ColumnsCount - 2}>
            <Typography className={classes.bulkEditText}>
              {selectedInvitesIds.length} Selected
            </Typography>
          </TableCell>
          <TableCell size="small">
            <IconButton
              className={classes.bulkEditBtn}
              onClick={() => setShowDeleteModal(true)}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </React.Fragment>
      }
      checkboxChecked={
        selectedInvitesIds.length > 0 &&
        selectedInvitesIds.length === props.invitationsList.length
      }
      checkboxIndeterminate={
        selectedInvitesIds.length > 0 &&
        selectedInvitesIds.length !== props.invitationsList.length
      }
      headerOptions={invitesTableHeaderOptions}
      onBulkSelect={onSelectAllClick}
    />
  );
  const onMenuOpenClick = (e, invite) => {
    setInvitesOptionAnchor(e.currentTarget);
    props.setFocusedInvite(invite);
  };
  const onMenuCloseClick = () => {
    setInvitesOptionAnchor(null);
    props.setFocusedInvite(null);
  };
  const onInviteDeleteClick = () => {
    setShowDeleteModal(true);
    setInvitesOptionAnchor(null);
  };
  const onConfirmDelete = async () => {
    setShowDeleteModal(false);
    var inviteIds = [];
    if (selectedInvitesIds?.length > 0) {
      inviteIds = selectedInvitesIds;
    } else if (props.focusedInvitation) {
      inviteIds = [props.focusedInvitation._id];
    }
    const { data, error } = await run(
      "DELETE",
      `${API_SERVER_URL}/manage/members/pendingInvites`,
      { inviteIds }
    );
    if (data && !error) {
      showSuccessSnackbar(
        `Successfully removed ${data?.data?.deleteCount} invitation(s)!`
      );
      setSelectedInvitesIds([]);
      props.setFocusedInvite(null);
      props.refreshInvitesList();
    }
  };
  const onCopyInvitationClick = () => {
    if (props.focusedInvitation?.invitationLink) {
      navigator.clipboard.writeText(props.focusedInvitation?.invitationLink);
      showSuccessSnackbar("Invitation link copied!");
    } else {
      showErrorSnackbar("Unable to copy invite link!");
    }
    props.setFocusedInvite(null);
    setInvitesOptionAnchor(null);
  };
  return (
    <React.Fragment>
      <CustomTable
        tableHeader={tableHeader}
        loading={props.invitationsLoading}
        tableBody={
          <TableBody>
            {props.invitationsList.map((invite, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>
                    <Checkbox
                      className={classes.checkbox}
                      onChange={() => onInviteSelect(invite._id)}
                      checked={selectedInvitesIds.includes(invite._id)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.lightHeadline}>
                      {invite.taskDetails?.toUser?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography className={classes.memberName}>
                        {invite.taskDetails?.fromUser?.name}
                      </Typography>
                      <Typography className={classes.memberEmail}>
                        {invite.taskDetails?.fromUser?.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.lightHeadline}>
                      {getDateStringFromTimeStamp(invite.createdAt)}
                    </Typography>
                    <Typography className={classes.dullDescription}>
                      {getTimeStringFromTimeStamp(invite.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={
                        invite.taskDetails?.toUser?.email === props.user?.email
                          ? classes.chipActive
                          : classes.chipInactive
                      }
                    >
                      {readableAdminRoles(invite.taskDetails?.toUser?.role)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.moreOptionBtn}>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={(e) => onMenuOpenClick(e, invite)}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
      />
      <Typography
        className={classes.lightHeadline}
        style={{ marginTop: 10, marginLeft: 10 }}
      >
        Note: Any invitation of not accepted will expire in 24 hours with
        respect to time of invite.
      </Typography>
      <Menu
        anchorEl={invitesOptionAnchor}
        open={Boolean(invitesOptionAnchor)}
        onClose={onMenuCloseClick}
      >
        <MenuItem onClick={onCopyInvitationClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <ContentPasteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>
            Copy Invite Link
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onInviteDeleteClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>
            Delete Invitation
          </ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteModal}
        description={`This will remove ${
          selectedInvitesIds.length ? selectedInvitesIds.length : 1
        } invitation(s). Are you sure you want to proceed?`}
        onClose={() => setShowDeleteModal(false)}
        onCancelClick={() => setShowDeleteModal(false)}
        onConfirmClick={onConfirmDelete}
      />
    </React.Fragment>
  );
};
const mapStoreToProps = (store) => ({
  ...store.membersReducer,
  ...store.userReducer,
});
const mapActionsToProps = {
  toggleMemberModal: MemberActions.toggleMemberModal,
  setFocusedInvite: MemberActions.setFocusedInvite,
  setLoadInvitations: MemberActions.setLoadInvitations,
  setInvitesLoading: MemberActions.setInvitesLoading,
  setInvitationsList: MemberActions.setInvitationsList,
  refreshInvitesList: MemberActions.refreshInvitesList,
};
export default connect(mapStoreToProps, mapActionsToProps)(PendingInvitations);
