import { Drawer } from "@mui/material";
import React from "react";

const LeftDrawer = (props) => {
  return (
    <Drawer anchor="left" open={props.open} onClose={props.onClose}>
      {props.children}
    </Drawer>
  );
};

export default LeftDrawer;
