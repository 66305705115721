import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  basicSwitch: {
    width: 58,
    height: 38,
    padding: "9px 13px 9px 12px",
    "& .MuiSwitch-switchBase": {
      left: 3,
      padding: 12,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(14px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "rgb(0, 167, 111)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "#ccc",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      color: "#fff",
    },
    "& .MuiSwitch-track": {
      borderRadius: 14,
      backgroundColor: "rgba(145, 158, 171, 0.48)",
      transition: "background-color ease-in 500ms",
    },
  },
}));

const CustomSwitch = (props) => {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            sx={{ m: 1 }}
            className={classes.basicSwitch}
            checked={props.checked}
            onChange={props.onSwitchChange}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
};

export default CustomSwitch;
