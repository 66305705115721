import LAYOUT_ACTIONS from "./actionTypes";

const initialState = {
  mobileLayoutOptions: {
    openLeftNavDrawer: false,
  },
  desktopLayoutOptions: {
    leftNavFullOpen: true,
  },
};

const reducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case LAYOUT_ACTIONS.CHANGE_MOBILE_LAYOUT:
      newState.mobileLayoutOptions = {
        ...newState.mobileLayoutOptions,
        ...action.payload,
      };
      return newState;
    case LAYOUT_ACTIONS.CHANGE_DESKTOP_LAYOUT:
      newState.desktopLayoutOptions = {
        ...newState.desktopLayoutOptions,
        ...action.payload,
      };
      return newState;
    default:
      return newState;
  }
};

export default reducer;
