import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  toggleCategorySidetray,
  setFocusedCategory,
  setCategoriesList,
  setPageDetails,
} from "../../../../redux/categoriesReducer/action";
import useStyles from "./styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toolbarOptions, defaultEmptyCategory } from "./default";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import Dropzone from "react-dropzone";
import {
  API_SERVER_URL,
  useAsyncFetchWithToken,
} from "../../../../utils/urlHelpers";
import { SnackbarContext } from "../../../../components/SnackbarContext/SnackbarContext";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";

const CategorySideTray = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const { data, loading, error, run } = useAsyncFetchWithToken();
  const [categoryDetails, setCategoryDetails] = useState({
    ...defaultEmptyCategory,
  });
  const [newImage, setNewImage] = useState(null);

  var saveFlow = categoryDetails?._id?.length ? "update" : "create";

  const onPublishStateToggle = ({ target }) => {
    setCategoryDetails({
      ...categoryDetails,
      state: target.checked ? "publish" : "draft",
    });
  };
  const setAdditionalInformation = (htmlText) => {
    if (props.openProductSideTray) {
      setCategoryDetails({
        ...categoryDetails,
        additionalInformation: htmlText,
      });
    }
  };
  const manageImageDrop = (uploadedFiles) => {
    let newCategoryImage = uploadedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )?.[0];
    setNewImage(newCategoryImage);
  };

  useEffect(() => {
    if (
      props.focusedCategoryDetails &&
      Object.keys(props.focusedCategoryDetails).length
    ) {
      setCategoryDetails({
        ...props.focusedCategoryDetails,
      });
    } else {
      setCategoryDetails({ ...defaultEmptyCategory });
      setNewImage(null);
    }
  }, [props.focusedCategoryDetails]);

  useEffect(() => {
    if (!error && !loading && data) {
      showSuccessSnackbar(
        saveFlow === "create"
          ? "Category created successfully!"
          : "Category updated successfully!"
      );
      if (saveFlow === "create") {
        var totalResults = props.pageDetails.totalResults + 1;
        var totalPages = props.pageDetails.totalPages;
        if (totalResults > totalPages * props.pageDetails.limit) {
          totalPages++;
        }
        props.setPageDetails({
          ...props.pageDetails,
          totalPages,
          totalResults,
        });
        var categoriesList = [...props.categoriesList];
        categoriesList.unshift({ ...data?.category });
        props.setCategoriesList([...categoriesList]);
      }
      setNewImage(null);
      props.setFocusedCategory({ ...data?.category });
    }
    if (error) {
      showErrorSnackbar(error);
    }
  }, [data, error, loading]);

  const onSaveClick = () => {
    const extraHaders = {
      "Content-type": "multipart/form-data",
    };
    var formData = new FormData();
    Object.keys(categoryDetails).forEach((key) => {
      if (typeof categoryDetails[key] === "object") {
        formData.append(key, JSON.stringify(categoryDetails[key]));
      } else {
        formData.append(key, categoryDetails[key]);
      }
    });
    formData.append("image", newImage);

    run(
      saveFlow === "update" ? "put" : "post",
      `${API_SERVER_URL}/manage/category`,
      formData,
      extraHaders
    );
  };
  return (
    <React.Fragment>
      <Drawer
        open={props.openCategorySideTray}
        classes={{ paper: classes.sideTray }}
        anchor="right"
        onClose={props.toggleCategorySidetray}
      >
        <Box className={classes.trayHeader}>
          <Box>
            <Typography className={classes.trayHeadline}>
              {saveFlow === "create"
                ? "Create a new category"
                : "Update Category"}
            </Typography>
            <Typography className={classes.trayDescription}>
              Create or update category
            </Typography>
          </Box>
          <IconButton onClick={props.toggleCategorySidetray}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.trayBody}>
          <Box className={classes.productImageryWrapper}>
            <Box className={classes.categoryImagesWrapper}>
              {newImage ?? categoryDetails.imageUrl ? (
                <Box className={classes.categoryImage}>
                  <img
                    src={newImage?.preview ?? categoryDetails.imageUrl}
                    alt=""
                  />
                  <Box
                    className={classes.removeImageOverlay}
                    // onClick={() => onImageRemove(idx, "existing")}
                  >
                    <Typography color="#fff">Click to update image</Typography>
                  </Box>
                </Box>
              ) : (
                <Dropzone onDrop={manageImageDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box className={classes.imageDropZone}>
                          <Box>
                            <img
                              src="/brandAssets/icons/uploadIcon.svg"
                              alt=""
                              id="uploadIcon"
                            />
                            <Typography component="h4">
                              Drag & drop image
                            </Typography>
                            <Typography>
                              or <Button>browse file</Button> on your computer
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </Box>
            <Box className={classes.productKPIs}>
              <CustomInput
                label="Category Name"
                fullWidth
                value={categoryDetails?.categoryName}
                onChange={({ target }) =>
                  setCategoryDetails({
                    ...categoryDetails,
                    categoryName: target.value,
                  })
                }
              />
              <Typography className={classes.secondaryHeadlines}>
                Description
              </Typography>
              <ReactQuill
                theme="snow"
                placeholder="Write something awesome about the category..."
                value={categoryDetails?.additionalInformation}
                onChange={setAdditionalInformation}
                className={classes.descriptionEditior}
                modules={{ toolbar: toolbarOptions }}
              />
              <CustomInput
                label="Short Description"
                fullWidth
                multiline
                rows={3}
                value={categoryDetails.shortDescription}
                onChange={({ target }) =>
                  setCategoryDetails({
                    ...categoryDetails,
                    shortDescription: target.value,
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.trayFooter}>
          <CustomSwitch
            checked={categoryDetails.state === "publish"}
            label={categoryDetails.state === "publish" ? "Publish" : "Draft"}
            onSwitchChange={onPublishStateToggle}
          />
          <CustomButton
            label={
              saveFlow === "create" ? "Create Category" : "Update Category"
            }
            onClick={onSaveClick}
          />
        </Box>
      </Drawer>
      {loading ? (
        <LoadingScreen
          loadingText={
            categoryDetails?.id?.length
              ? "Updating category details..."
              : "Creating category..."
          }
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStoreToProps = (store) => ({
  ...store.categoriesReducer,
});
const mapActionsToProps = {
  toggleCategorySidetray,
  setFocusedCategory,
  setCategoriesList,
  setPageDetails,
};
export default connect(mapStoreToProps, mapActionsToProps)(CategorySideTray);
