import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  toggleProductSidetrayTray,
  setFocusedProduct,
  setProductsList,
  setPageDetails,
} from "../../../../redux/productsReducer/action";
import useStyles from "./styles";
import Carousel from "../../../../components/Carousel/Carousel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  stockOptions,
  toolbarOptions,
  defaultEmptyProduct,
  getCategoriesOptions,
} from "./default";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import Dropzone from "react-dropzone";
import {
  API_SERVER_URL,
  useAsyncFetchWithToken,
} from "../../../../utils/urlHelpers";
import { SnackbarContext } from "../../../../components/SnackbarContext/SnackbarContext";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import useCategories from "./hooks";
import { InfoRounded } from "@mui/icons-material";

const ProductSideTray = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const { data, loading, error, run } = useAsyncFetchWithToken();

  const { fetchAllCategories, allCategories, categoriesLoading } =
    useCategories();

  const [productDetails, setProductDetails] = useState(
    JSON.parse(JSON.stringify(defaultEmptyProduct))
  );
  const [newImages, setNewImages] = useState([]);

  var saveFlow = productDetails?._id?.length ? "update" : "create";

  const setAdditionalInformation = (htmlText) => {
    if (props.openProductSideTray) {
      setProductDetails({
        ...productDetails,
        additionalInformation: htmlText,
      });
    }
  };
  const manageImageDrop = (uploadedFiles) => {
    var newProductImages = [...newImages];
    newProductImages = newProductImages.concat(
      uploadedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setNewImages(newProductImages);
  };
  const onImageRemove = (index, imageType) => {
    if (imageType === "existing") {
      productDetails.imageUrls.splice(index, 1);
      setProductDetails({
        ...productDetails,
        imageUrls: [...productDetails.imageUrls],
      });
    } else {
      newImages.splice(index, 1);
      setNewImages([...newImages]);
    }
  };
  const onOptionValueChange = (value, optionType, property) => {
    var availableOptions = [...productDetails.availableOptions];
    let index = availableOptions.findIndex((x) => x.variantName === optionType);
    availableOptions[index][property] = value;
    setProductDetails({ ...productDetails, availableOptions });
  };
  const onProductRootLevelValueChange = (value, property) => {
    let _productDetails = JSON.parse(JSON.stringify(productDetails));
    _productDetails[property] = value;
    if (property === "showPlainPrintedTags") {
      if (value) {
        let printedVariant = defaultEmptyProduct.availableOptions.find(
          (x) => x.variantName === "printed"
        );
        if (printedVariant) {
          _productDetails.availableOptions.push({ ...printedVariant });
        }
      } else {
        let _printedVariantIdx = _productDetails.availableOptions.findIndex(
          (x) => x.variantName === "printed"
        );
        if (_printedVariantIdx >= 0) {
          _productDetails.availableOptions.splice(_printedVariantIdx, 1);
        }
      }
      if (_productDetails.availableOptions.length === 1) {
        _productDetails.availableOptions[0].isAvailable = true;
      }
    }
    if (property === "categoryId") {
      let foundCategory = allCategories.find((cat) => cat._id === value);
      _productDetails.categoryName = foundCategory?.categoryName ?? "";
    }
    setProductDetails(_productDetails);
  };

  useEffect(() => {
    if (
      props.focusedProductDetails &&
      Object.keys(props.focusedProductDetails).length
    ) {
      setProductDetails({
        ...props.focusedProductDetails,
        imageUrls: [...props.focusedProductDetails.imageUrls],
      });
    } else {
      setProductDetails(JSON.parse(JSON.stringify(defaultEmptyProduct)));
    }
    setNewImages([]);
  }, [props.focusedProductDetails]);

  useEffect(() => {
    if (!error && !loading && data) {
      showSuccessSnackbar(
        saveFlow === "create"
          ? "Product created successfully!"
          : "Product updated successfully!"
      );
      if (saveFlow === "create") {
        var totalResults = props.pageDetails.totalResults + 1;
        var totalPages = props.pageDetails.totalPages;
        if (totalResults > totalPages * props.pageDetails.limit) {
          totalPages++;
        }
        props.setPageDetails({
          ...props.pageDetails,
          totalPages,
          totalResults,
        });
        var productsList = [...props.productsList];
        productsList.unshift({ ...data?.product });
        props.setProductsList([...productsList]);
      }
      props.setFocusedProduct({ ...data?.product });
    }
    if (error) {
      showErrorSnackbar(error);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (
      props.openProductSideTray &&
      !allCategories.length &&
      !categoriesLoading
    ) {
      fetchAllCategories();
    }
  }, [props.openProductSideTray]);

  const onSaveClick = () => {
    const extraHaders = {
      "Content-type": "multipart/form-data",
    };
    var formData = new FormData();
    Object.keys(productDetails).forEach((key) => {
      if (typeof productDetails[key] === "object") {
        formData.append(key, JSON.stringify(productDetails[key]));
      } else {
        formData.append(key, productDetails[key]);
      }
    });
    newImages.forEach((image) => formData.append("images", image));

    run(
      saveFlow === "update" ? "put" : "post",
      `${API_SERVER_URL}/manage/product`,
      formData,
      extraHaders
    );
  };

  const allCategoriesOptions = useMemo(
    () => getCategoriesOptions(allCategories),
    [allCategories]
  );

  const printedVariantDetails = useMemo(() => {
    return (
      productDetails?.availableOptions?.find(
        (variant) => variant.variantName === "printed"
      ) || null
    );
  }, [productDetails.availableOptions]);

  return (
    <React.Fragment>
      <Drawer
        open={props.openProductSideTray}
        classes={{ paper: classes.sideTray }}
        anchor="right"
        onClose={props.toggleProductSidetrayTray}
      >
        <Box className={classes.trayHeader}>
          <Box>
            <Typography className={classes.trayHeadline}>
              {saveFlow === "create"
                ? "Create a new product"
                : "Update Product"}
            </Typography>
            <Typography className={classes.trayDescription}>
              Create or update product
            </Typography>
          </Box>
          <IconButton onClick={props.toggleProductSidetrayTray}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.trayBody}>
          <Box className={classes.productImageryWrapper}>
            <Box className={classes.productImagesWrapper}>
              <Carousel>
                {newImages.map((image, idx) => {
                  return (
                    <Box className={classes.productImage} key={idx}>
                      <img src={image.preview} alt="" />
                      <Box
                        className={classes.removeImageOverlay}
                        onClick={() => onImageRemove(idx, "new")}
                      >
                        <Typography color="#fff">Remove Image</Typography>
                      </Box>
                    </Box>
                  );
                })}
                {productDetails.imageUrls?.map((url, idx) => {
                  return (
                    <Box className={classes.productImage} key={idx}>
                      <img src={url} alt="" />
                      <Box
                        className={classes.removeImageOverlay}
                        onClick={() => onImageRemove(idx, "existing")}
                      >
                        <Typography color="#fff">Remove Image</Typography>
                      </Box>
                    </Box>
                  );
                })}
                <Dropzone onDrop={manageImageDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box className={classes.imageDropZone}>
                          <Box>
                            <img
                              src="/brandAssets/icons/uploadIcon.svg"
                              alt=""
                              id="uploadIcon"
                            />
                            <Typography component="h4">
                              Drag & drop images
                            </Typography>
                            <Typography>
                              or <Button>browse files</Button> on your computer
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Carousel>
            </Box>
            <Box className={classes.productKPIs}>
              <CustomInput
                label="Product Name"
                fullWidth
                value={productDetails?.productName}
                onChange={({ target }) =>
                  setProductDetails({
                    ...productDetails,
                    productName: target.value,
                  })
                }
              />
              <CustomSelect
                options={allCategoriesOptions}
                selection={productDetails.categoryId}
                label="Product Category"
                onSelectionChange={({ target }) =>
                  onProductRootLevelValueChange(target.value, "categoryId")
                }
              />
              <Typography className={classes.secondaryHeadlines}>
                Description
              </Typography>
              <ReactQuill
                theme="snow"
                placeholder="Write something awesome about the product..."
                value={productDetails?.additionalInformation}
                onChange={setAdditionalInformation}
                className={classes.descriptionEditior}
                modules={{ toolbar: toolbarOptions }}
              />
              <CustomInput
                label="Refernce Number"
                fullWidth
                value={productDetails?.refNumber}
                onChange={({ target }) =>
                  setProductDetails({
                    ...productDetails,
                    refNumber: target.value,
                  })
                }
              />
              <Box className={classes.rowAlignment}>
                <CustomSwitch
                  checked={productDetails?.allowEnquiry}
                  onSwitchChange={({ target }) =>
                    onProductRootLevelValueChange(
                      target.checked,
                      "allowEnquiry"
                    )
                  }
                />
                <Typography>Allow enquiry?</Typography>
              </Box>
              <Box className={classes.rowAlignment}>
                <CustomSwitch
                  checked={productDetails?.showPlainPrintedTags}
                  onSwitchChange={({ target }) =>
                    onProductRootLevelValueChange(
                      target.checked,
                      "showPlainPrintedTags"
                    )
                  }
                />
                <Typography>Show Printed/Plain Tags?</Typography>
                <Tooltip title="Uncheck this option for the product categories which have only one variant and i.e. plain.">
                  <InfoRounded fontSize="small" />
                </Tooltip>
              </Box>
              <Typography className={classes.secondaryHeadlines}>
                Available Order Options
              </Typography>
              <Box className={classes.rowAlignment}>
                <CustomSwitch
                  checked={
                    productDetails?.availableOptions?.find(
                      (x) => x.variantName === "plain"
                    )?.isAvailable
                  }
                  disabled={!printedVariantDetails}
                  onSwitchChange={({ target }) =>
                    onOptionValueChange(target.checked, "plain", "isAvailable")
                  }
                />
                <Box style={{ width: "100%" }}>
                  <CustomInput
                    disabled={
                      !productDetails?.availableOptions?.find(
                        (x) => x.variantName === "plain"
                      )?.isAvailable
                    }
                    label="Plain Price"
                    fullWidth
                    type="number"
                    value={
                      productDetails?.availableOptions?.find(
                        (x) => x.variantName === "plain"
                      )?.price
                    }
                    onChange={({ target }) =>
                      onOptionValueChange(target.value, "plain", "price")
                    }
                  />
                  {productDetails?.availableOptions?.find(
                    (x) => x.variantName === "plain"
                  )?.isAvailable ? (
                    <Box className={classes.rowAlignment}>
                      <CustomInput
                        label="Minimum Order Quantity"
                        fullWidth
                        type="number"
                        value={
                          productDetails?.availableOptions?.find(
                            (x) => x.variantName === "plain"
                          )?.MOQ
                        }
                        onChange={({ target }) =>
                          onOptionValueChange(target.value, "plain", "MOQ")
                        }
                      />
                      <CustomInput
                        label="Quantity Multiple"
                        fullWidth
                        type="number"
                        value={
                          productDetails?.availableOptions?.find(
                            (x) => x.variantName === "plain"
                          )?.QM
                        }
                        onChange={({ target }) =>
                          onOptionValueChange(target.value, "plain", "QM")
                        }
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
              {printedVariantDetails ? (
                <Box className={classes.rowAlignment}>
                  <CustomSwitch
                    checked={
                      productDetails?.availableOptions?.find(
                        (x) => x.variantName === "printed"
                      )?.isAvailable
                    }
                    onSwitchChange={({ target }) =>
                      onOptionValueChange(
                        target.checked,
                        "printed",
                        "isAvailable"
                      )
                    }
                  />
                  <Box style={{ width: "100%" }}>
                    <CustomInput
                      label="Printed Price"
                      fullWidth
                      type="number"
                      value={printedVariantDetails.price}
                      onChange={({ target }) =>
                        onOptionValueChange(target.value, "printed", "price")
                      }
                    />
                    {printedVariantDetails.isAvailable ? (
                      <Box className={classes.rowAlignment}>
                        <CustomInput
                          label="Minimum Order Quantity"
                          fullWidth
                          type="number"
                          value={printedVariantDetails.MOQ}
                          onChange={({ target }) =>
                            onOptionValueChange(target.value, "printed", "MOQ")
                          }
                        />
                        <CustomInput
                          label="Quantity Multiple"
                          fullWidth
                          type="number"
                          value={printedVariantDetails.QM}
                          onChange={({ target }) =>
                            onOptionValueChange(target.value, "printed", "QM")
                          }
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : null}
              <Box className={classes.rowAlignment}>
                <CustomInput
                  label="Product Weight in grams"
                  type="number"
                  fullWidth
                  value={productDetails.weight}
                  onChange={({ target }) =>
                    setProductDetails({
                      ...productDetails,
                      weight: target.value,
                    })
                  }
                />
                <CustomSelect
                  options={stockOptions}
                  selection={productDetails.inStock}
                  label="Availability"
                  onSelectionChange={({ target }) =>
                    onProductRootLevelValueChange(target.value, "inStock")
                  }
                />
              </Box>
              <CustomInput
                label="Short Description"
                fullWidth
                multiline
                rows={3}
                value={productDetails.shortDescription}
                onChange={({ target }) =>
                  setProductDetails({
                    ...productDetails,
                    shortDescription: target.value,
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.trayFooter}>
          <CustomSwitch
            checked={productDetails.state === "publish"}
            label={productDetails.state === "publish" ? "Publish" : "Draft"}
            onSwitchChange={({ target }) =>
              onProductRootLevelValueChange(
                target.checked ? "publish" : "draft",
                "state"
              )
            }
          />
          <CustomButton
            label={saveFlow === "create" ? "Create Product" : "Update Product"}
            onClick={onSaveClick}
          />
        </Box>
      </Drawer>
      {loading ? (
        <LoadingScreen
          loadingText={
            productDetails?.id?.length
              ? "Updating product details..."
              : "Creating product..."
          }
        />
      ) : null}
      {categoriesLoading ? (
        <LoadingScreen loadingText="Fetching available categories details..." />
      ) : null}
    </React.Fragment>
  );
};

const mapStoreToProps = (store) => ({
  ...store.productsReducer,
});
const mapActionsToProps = {
  toggleProductSidetrayTray,
  setFocusedProduct,
  setProductsList,
  setPageDetails,
};
export default connect(mapStoreToProps, mapActionsToProps)(ProductSideTray);
