import { Box, Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useStyles from "./styles";

const getBeautifiedNames = (name) => {
  if (name && name.length > 0) {
    name = name.replace(/([a-z])([A-Z])/g, "$1 $2");
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  return null;
};

const HeaderWithBreadcrums = (props) => {
  const [availablePaths, setAvailablePaths] = useState([]);
  const location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    setAvailablePaths(location.pathname.slice(1).split("/"));
  }, [location.pathname]);
  return (
    <Box className={classes.headerWrapper}>
      <Box>
        <Typography component="h4" className={classes.headerTitle}>
          {availablePaths.length
            ? getBeautifiedNames(availablePaths[availablePaths.length - 1])
            : null}
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<Box className={classes.breadcrumsSeparator} />}
        >
          {availablePaths.map((link, idx) => {
            return (
              <Link
                className={`${classes.bradcrumsLink} ${
                  availablePaths.length - 1 === idx
                    ? classes.bradcrumsLinkInactive
                    : ""
                }`}
                to={
                  availablePaths.length - 1 === idx
                    ? null
                    : `/${availablePaths.slice(0, idx + 1).join("/")}`
                }
                key={idx}
              >
                {getBeautifiedNames(link)}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Box>{props.cta}</Box>
    </Box>
  );
};

export default HeaderWithBreadcrums;
