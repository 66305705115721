import axios from "axios";
import { useCallback, useState } from "react";
import * as localStorageHelpers from "./localStorageHelper";

export const API_SERVER_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.lovelyprinter.in"
    : "http://localhost:8000";

export const useFetch = async (method, url, body = null, extraOptions = {}) => {
  let data = null,
    error = null;
  let headers = {};
  if (extraOptions?.authenticate) {
    headers[
      "Authorization"
    ] = `Bearer ${localStorageHelpers.getAccessTokenFromLS()}`;
  }
  if (extraOptions?.useNoCache) {
    headers["Cache-Control"] = "no-cache";
    headers["Pragma"] = "no-cache";
  }
  try {
    const resp = await axios({
      method: method,
      url: url,
      data: body,
      headers: headers,
    });
    data = resp?.data;
  } catch (err) {
    error = err?.response?.data?.message || "Something went wrong!";
  }

  return { data, error };
};

export const useAsyncFetchWithToken = (defaultData) => {
  const [data, setData] = useState({
    data: defaultData ?? null,
    error: null,
    loading: false,
  });

  const run = useCallback(
    async (method = "GET", apiEndpoint, extraData, extraHeaders = {}) => {
      const headers = {
        ...axios.defaults.headers,
        Authorization: `Bearer ${localStorageHelpers.getAccessTokenFromLS()}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        ...extraHeaders,
      };
      try {
        setData({ data: null, error: null, loading: true });
        const response = await axios({
          method: method,
          url: apiEndpoint,
          data: extraData,
          headers: headers,
        });
        const result = { data: response?.data, error: null, loading: false };
        setData(result);
        return result;
      } catch (error) {
        const result = {
          data: null,
          error: error?.response?.data?.message || "Something went wrong!",
          loading: false,
        };
        setData(result);
        return result;
      }
    },
    []
  );

  return {
    ...data,
    run,
  };
};

export const getUrlWithSearchParams = (url, searchParams) => {
  const searchParamsStr = new URLSearchParams(searchParams);
  return `${url}?${searchParamsStr.toString()}`;
};

export const verifyToken = async () => {
  const headers = {
    Authorization: `Bearer ${localStorageHelpers.getAccessTokenFromLS()}`,
  };
  const resp = await axios.get(`${API_SERVER_URL}/manage/verifyToken`, {
    headers: headers,
  });
  return resp?.data;
};
