import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const LoadingScreen = (props) => {
  return (
    <Box
      style={{
        backgroundColor: "rgb(0,0,0, 0.7)",
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 2000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,

        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <Typography style={{ color: "#fff" }} component="span">
        {props.loadingText ?? "Loading ...."}
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
