import React, { useContext, useEffect, useState } from "react";
import { CustomTable } from "../../../../components/CTable/CustomTables";
import CustomTableHeader from "../../../../components/CTable/components/CustomTableHeader";
import {
  Box,
  Checkbox,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
} from "@mui/icons-material";
import ImagesearchRollerOutlinedIcon from "@mui/icons-material/ImagesearchRollerOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AddIcon from "@mui/icons-material/Add";

import useStyles from "./styles";
import { getProductPreviewPageUrl, tableHeaderOptions } from "./defaults";
import { Link } from "react-router-dom";
import {
  getDateStringFromTimeStamp,
  getTimeStringFromTimeStamp,
} from "../../../../utils/commonHelpers";
import HeaderWithBreadcrums from "../../components/HeaderWithBreadcrums/HeaderWithBreadcrums";
import { connect } from "react-redux";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {
  API_SERVER_URL,
  getUrlWithSearchParams,
  useAsyncFetchWithToken,
} from "../../../../utils/urlHelpers";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { SnackbarContext } from "../../../../components/SnackbarContext/SnackbarContext";
import * as PRODUCT_ACTIONS from "../../../../redux/productsReducer/action";

const ColumnsCount = 8;

const Products = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [productOptionAnchor, setProductOptionAnchor] = useState(null);
  const [searchDetails, setSearchDetails] = useState({});
  const [selectedProductsIds, setSelectedProductsIds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { run } = useAsyncFetchWithToken();
  useEffect(() => {
    const ac = new AbortController();
    var params = {
      ...props.pageDetails,
      ...searchDetails,
    };
    var urlToHit = getUrlWithSearchParams(
      `${API_SERVER_URL}/manage/product`,
      params
    );
    const fetchProducts = async () => {
      props.setProductsLoading(true);
      const { data, error } = await run("GET", urlToHit);
      if (data && !error) {
        props.setProductsList([...data?.data]);
        props.setPageDetails({
          totalPages: data?.totalPages,
          totalResults: data?.totalResults,
          page: data?.currentPage,
          limit: data?.limit,
        });
      }
      props.setFetchError(error);
      props.setProductsLoading(false);
    };
    fetchProducts();
    return () => ac.abort();
  }, [
    props.pageDetails.page,
    props.pageDetails.limit,
    searchDetails,
    shouldRefresh,
  ]);

  useEffect(() => {
    if (!props.productsLoading && props.error) {
      showErrorSnackbar(
        props.error?.response?.data?.message || "Something went wrong!"
      );
    }
  }, [props.error, props.productsLoading]);

  const onSelectAllClick = () => {
    var allProductIds = props.productsList.map((x) => x._id);
    if (
      selectedProductsIds.length === props.productsList.length ||
      selectedProductsIds.length > 0
    ) {
      setSelectedProductsIds([]);
    } else {
      setSelectedProductsIds(allProductIds);
    }
  };
  const onProductSelect = (productId) => {
    var atIndex = selectedProductsIds.indexOf(productId);
    if (atIndex > -1) {
      selectedProductsIds.splice(atIndex, 1);
      setSelectedProductsIds([...selectedProductsIds]);
    } else {
      selectedProductsIds.push(productId);
      setSelectedProductsIds([...selectedProductsIds]);
    }
  };
  const tableHeader = (
    <CustomTableHeader
      haveBulkOptions={selectedProductsIds.length}
      haveCheckbox={true}
      bulkOperation={
        <React.Fragment>
          <TableCell size="small" colSpan={ColumnsCount - 2}>
            <Typography className={classes.bulkEditText}>
              {selectedProductsIds.length} Selected
            </Typography>
          </TableCell>
          <TableCell size="small">
            <IconButton
              className={classes.bulkEditBtn}
              onClick={() => setShowDeleteModal(true)}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </React.Fragment>
      }
      checkboxChecked={
        selectedProductsIds.length > 0 &&
        selectedProductsIds.length === props.productsList.length
      }
      checkboxIndeterminate={
        selectedProductsIds.length > 0 &&
        selectedProductsIds.length !== props.productsList.length
      }
      headerOptions={tableHeaderOptions}
      onBulkSelect={onSelectAllClick}
    />
  );
  const onMenuOpenClick = (e, product) => {
    setProductOptionAnchor(e.currentTarget);
    props.setFocusedProduct(product);
  };
  const onMenuCloseClick = () => {
    props.setFocusedProduct(null);
    setProductOptionAnchor(null);
  };
  const onProductEditClick = () => {
    setProductOptionAnchor(null);
    props.toggleProductSidetrayTray();
  };
  const onProductDeleteClick = () => {
    setShowDeleteModal(true);
    setProductOptionAnchor(null);
  };
  const onConfirmDelete = async () => {
    setShowDeleteModal(false);
    var productIds = [];
    if (selectedProductsIds?.length > 0) {
      productIds = selectedProductsIds;
    } else if (props.focusedProductDetails) {
      productIds = [props.focusedProductDetails._id];
    }
    const { data, error } = await run(
      "DELETE",
      `${API_SERVER_URL}/manage/product`,
      { productIds }
    );
    if (data && !error) {
      showSuccessSnackbar(
        `Successfully deleted ${data?.data?.deleteCount} products!`
      );
      setSelectedProductsIds([]);
      props.setFocusedProduct(null);
      setShouldRefresh(!shouldRefresh);
    }
  };
  return (
    <React.Fragment>
      <HeaderWithBreadcrums
        cta={
          <CustomButton
            label="New Product"
            onClick={props.toggleProductSidetrayTray}
            icon={<AddIcon color="inherit" />}
          />
        }
      />
      <CustomTable
        tableHeader={tableHeader}
        loading={props.productsLoading}
        tableBody={
          <TableBody>
            {props.productsList
              .slice(0, props.pageDetails.limit)
              .map((product, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      <Checkbox
                        className={classes.checkbox}
                        onChange={() => onProductSelect(product._id)}
                        checked={selectedProductsIds.includes(product._id)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className={classes.productNameContainer}>
                        <img
                          className={classes.productImage}
                          src={
                            product.imageUrls?.length
                              ? product.imageUrls[0]
                              : ""
                          }
                          alt="Product"
                        />
                        <Box>
                          <Link
                            to={getProductPreviewPageUrl(product)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.productName}
                          >
                            {product.productName}
                          </Link>
                          <Typography className={classes.productReligion}>
                            {product.religion}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.lightHeadline}>
                        {getDateStringFromTimeStamp(product.createdAt)}
                      </Typography>
                      <Typography className={classes.dullDescription}>
                        {getTimeStringFromTimeStamp(product.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={
                        product.inStock
                          ? classes.productStockAvailable
                          : classes.productStockUnavailable
                      }
                    >
                      <LinearProgress
                        color="inherit"
                        variant="determinate"
                        value={70}
                      />
                      <Typography className={classes.dullDescription}>
                        {product.inStock ? "In stock" : "Out of stock"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {product.availableOptions?.map((option, idx) => {
                        return (
                          <Typography
                            key={idx}
                            className={classes.lightHeadline}
                          >
                            {`₹ ${option.price}`}
                          </Typography>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      {product.availableOptions?.map((option, idx) => {
                        return (
                          <Box
                            key={idx}
                            className={classes.lightHeadline}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            {option.variantName === "plain" && (
                              <InsertDriveFileOutlinedIcon
                                fontSize="inherit"
                                color="inherit"
                              />
                            )}
                            {option.variantName === "printed" && (
                              <ImagesearchRollerOutlinedIcon
                                fontSize="inherit"
                                color="inherit"
                              />
                            )}{" "}
                            <Typography className={classes.dullDescription}>
                              {option.variantName}
                            </Typography>
                          </Box>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      <Typography
                        className={
                          product.state === "published"
                            ? classes.chipActive
                            : classes.chipInactive
                        }
                      >
                        {product.state}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.moreOptionBtn}>
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={(e) => onMenuOpenClick(e, product)}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 200]}
                count={props.pageDetails.totalResults}
                onPageChange={(_, page) =>
                  props.setPageDetails({ ...props.pageDetails, page })
                }
                onRowsPerPageChange={({ target }) =>
                  props.setPageDetails({
                    ...props.pageDetails,
                    limit: target.value,
                    page: 0,
                  })
                }
                page={props.pageDetails.page}
                rowsPerPage={props.pageDetails.limit}
              />
            </TableRow>
          </TableBody>
        }
      />
      <Menu
        anchorEl={productOptionAnchor}
        open={Boolean(productOptionAnchor)}
        onClose={onMenuCloseClick}
      >
        <MenuItem onClick={onProductEditClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <BorderColorOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={onProductDeleteClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteModal}
        description={`This will delete ${
          selectedProductsIds.length ? selectedProductsIds.length : 1
        } product(s). Are you sure you want to proceed?`}
        onClose={() => setShowDeleteModal(false)}
        onCancelClick={() => setShowDeleteModal(false)}
        onConfirmClick={onConfirmDelete}
      />
    </React.Fragment>
  );
};
const mapStoreToProps = (store) => ({
  ...store.productsReducer,
});
const mapActionsToProps = {
  setPageDetails: PRODUCT_ACTIONS.setPageDetails,
  setFetchError: PRODUCT_ACTIONS.setFetchError,
  setProductsList: PRODUCT_ACTIONS.setProductsList,
  setProductsLoading: PRODUCT_ACTIONS.setProductsLoading,
  toggleProductSidetrayTray: PRODUCT_ACTIONS.toggleProductSidetrayTray,
  setFocusedProduct: PRODUCT_ACTIONS.setFocusedProduct,
};
export default connect(mapStoreToProps, mapActionsToProps)(Products);
