import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import useStyles from "../styles";

const CustomTableHeader = ({
  headerOptions,
  haveBulkOptions,
  bulkOperation,
  haveCheckbox,
  checkboxChecked,
  checkboxIndeterminate,
  onBulkSelect = () => {},
}) => {
  const classes = useStyles();

  return (
    <TableHead
      className={
        haveBulkOptions ? classes.activeTableHeader : classes.tableHeader
      }
    >
      <TableRow>
        {haveCheckbox ? (
          <TableCell size="small">
            <Checkbox
              indeterminate={checkboxIndeterminate}
              checked={checkboxChecked}
              className={classes.tableHeaderChechbox}
              onChange={onBulkSelect}
              size="small"
            />
          </TableCell>
        ) : null}
        {haveBulkOptions
          ? bulkOperation
          : headerOptions.map((option, idx) => {
              return (
                <TableCell size="small" key={idx}>
                  {option.type === "blank" ? null : (
                    <Typography className={classes.tableHeaderTitles}>
                      {option.label}
                    </Typography>
                  )}
                </TableCell>
              );
            })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
