const tableHeaderOptions = [
  {
    type: "text",
    label: "Product",
  },
  {
    type: "text",
    label: "Created at",
  },
  {
    type: "text",
    label: "Stock",
  },
  {
    type: "text",
    label: "Price",
  },
  {
    type: "text",
    label: "Variants",
  },
  {
    type: "text",
    label: "Publish",
  },
  {
    type: "blank",
    label: "",
  },
];

const getProductPreviewPageUrl = (product) => {
  return `${process.env.REACT_APP_FRONTEND_URI}/product/${product._id}`;
};

const dummyData = [
  {
    _id: "64c39fef3d197604d69d24e2",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [],
    weight: 0.05,
    inStock: true,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "cc6b1c94-380f-415c-ac08-da34c094e742",
    __v: 0,
  },
  {
    _id: "64c39ff23d197604d69d24e4",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: true,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "26448db2-6e7d-47d3-ab50-4ad34730b00c",
    __v: 0,
  },
  {
    _id: "64c3a08f7833e763e4cdeb70",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: true,
    state: "published",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "27b23fa7-1e60-419a-b7c5-20168519ce6f",
    __v: 0,
  },
  {
    _id: "64c7849a28a8f50bde56acda",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: false,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "8TyjevCZKb9cBDrZR84xKi",
    __v: 0,
  },
  {
    _id: "64c784a028a8f50bde56acdc",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: true,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "eCRiwE5q2j7miAN1n8dcMz",
    __v: 0,
  },
  {
    _id: "64c784a028a8f50bde56acde",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: true,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "9KyaePg6f5GXC2w4yrKpwf",
    __v: 0,
  },
  {
    _id: "64c78dea5934df54bc0e5d60",
    productName: "New testing product",
    refNumber: "13232",
    categoryIds: [],
    imageUrls: [
      "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_17.jpg",
    ],
    weight: 0.05,
    inStock: true,
    state: "draft",
    religion: "hindu",
    createdAt: 1690820161554,
    availableOptions: {
      plain: true,
      printed: true,
    },
    plainPrice: 20,
    printedPrice: 25.5,
    productId: "6nquKZNExnWTEJrEYKZYXg",
    __v: 0,
  },
];
export { tableHeaderOptions, getProductPreviewPageUrl, dummyData };
