export const AppTypes = {
  GoogleMerchantCenterContentApi: "GoogleMerchantCenterContentApi",
  AllConfiguredApps: "AllConfiguredApps",
};

export const AppEndPoints = {
  [AppTypes.GoogleMerchantCenterContentApi]:
    "additionalApps/googleMerchantCenter",
  [AppTypes.AllConfiguredApps]: "additionalApps/allConfiguredApps",
};

export const AllApps = [
  {
    appName: "Google Merchant Center",
    appIcon:
      "https://cdn.lovelyprinter.in/appIcons/googleMerchantCenterIcon.jpg",
    tokensInfo: {},
    appType: AppTypes.GoogleMerchantCenterContentApi,
    appDescription: "To manage inventory in Google Merchant center",
    isConfigured: false,
    settings: {},
  },
];
