import CATEGORY_ACTIONS from "./actionTypes";

export const setCategoriesList = (categories) => {
  return {
    type: CATEGORY_ACTIONS.SET_CATEGORIES_LIST,
    payload: categories,
  };
};
export const setPageDetails = (details) => {
  return {
    type: CATEGORY_ACTIONS.SET_CATEGORIES_PAGE_DETAILS,
    payload: details,
  };
};
export const setFetchError = (error) => {
  return {
    type: CATEGORY_ACTIONS.SET_CATEGORIES_FETCH_ERROR,
    payload: error,
  };
};

export const setCategoriesLoading = (loadingState) => {
  return {
    type: CATEGORY_ACTIONS.SET_CATEGORIES_LOADING,
    payload: loadingState,
  };
};

export const toggleCategorySidetray = () => {
  return {
    type: CATEGORY_ACTIONS.TOGGLE_CATEGORY_SIDETRAY_TRAY,
  };
};
export const setFocusedCategory = (categoryDetails) => {
  return {
    type: CATEGORY_ACTIONS.SET_FOCUSED_CATEGORY,
    payload: categoryDetails,
  };
};
