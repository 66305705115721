import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../../components/Header/Header";
import { Box } from "@mui/material";
import useStyles from "./styles";
import LeftMenu from "../../components/LeftNav/LeftNav";
import { connect } from "react-redux";
import {
  setDesktopLayoutOptions,
  setMobileLayoutOptions,
} from "../../redux/layoutReducer/action";
import Dashboard from "./pages/Dashboard/Dashboard";
import Products from "./pages/Products/Products";
import ProductSideTray from "./pages/ProductSideTray/ProductSideTray";
import AdminApps from "./pages/AdminApps/AdminApps";
import Members from "./pages/Members/Members";
import MemberModal from "./pages/Members/MemberModal/MemberModal";
import Categories from "./pages/Categories/Categories";
import CategorySideTray from "./pages/CategorySideTray/CategorySideTray";

const ProtectedPagesLayout = (props) => {
  const { layoutOptions } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.mainWrapper}>
        <Box className={classes.leftComponent}>
          <LeftMenu.LeftSideNav
            style={{
              width: layoutOptions.desktopLayoutOptions?.leftNavFullOpen
                ? 280
                : 87,
            }}
          />
        </Box>
        <Box className={classes.rightComponent}>
          <Header
            className={classes.responsiveHeader}
            style={{
              width: layoutOptions.desktopLayoutOptions?.leftNavFullOpen
                ? "calc(100vw - 281px)"
                : "calc(100vw - 88px)",
            }}
          />
          <Box
            sx={{
              py: 10,
            }}
          >
            <Box className={classes.protectedRouteBody}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/members" element={<Members />} />
                <Route path="/products" element={<Products />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/orders" element={<Dashboard />} />
                <Route path="/adminApps/*" element={<AdminApps />} />
              </Routes>
            </Box>
          </Box>
        </Box>
      </Box>
      <LeftMenu.LeftNavDrawer />
      <CategorySideTray />
      <ProductSideTray />
      <MemberModal />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  layoutOptions: { ...state.layoutReducer },
});
const mapActionsToProps = {
  setMobileLayoutOptions,
  setDesktopLayoutOptions,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(ProtectedPagesLayout);
