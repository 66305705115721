import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LeftDrawer from "../LeftDrawer/LeftDrawer";
import LeftArrowIcon from "@mui/icons-material/ChevronLeft";
import RightArrowIcon from "@mui/icons-material/ChevronRight";
import {
  setMobileLayoutOptions,
  setDesktopLayoutOptions,
} from "../../redux/layoutReducer/action";
import { connect } from "react-redux";
import useStyles from "./styles";
import { getAllowedManagementNavLinks } from "./defaults";
import { Link, useLocation } from "react-router-dom";

const LeftNavRaw = (props) => {
  const { layoutOptions, user } = props;
  const location = useLocation();
  const [openManagement, setOpenManagement] = useState(true);
  var activeLink = location.pathname;
  var allManagementLinksToRender = getAllowedManagementNavLinks(user?.role);
  var classes = useStyles();
  return (
    <Box>
      <Box className={classes.brandingArea}>
        <img
          src="/brandAssets/logo.svg"
          alt=""
          style={
            props.isDesktop
              ? {
                  height: layoutOptions.desktopLayoutOptions?.leftNavFullOpen
                    ? 50
                    : 20,
                }
              : {}
          }
        />
      </Box>
      <ListItem button onClick={() => setOpenManagement(!openManagement)}>
        <ListItemText
          primary={
            <Typography
              className={`${classes.mainSectionHeading} ${
                props.isDesktop &&
                layoutOptions.desktopLayoutOptions?.leftNavFullOpen
                  ? ""
                  : classes.displayNone
              }`}
            >
              Management
            </Typography>
          }
        />
      </ListItem>
      <Collapse in={openManagement} timeout="auto" unmountOnExit>
        <List disablePadding>
          {allManagementLinksToRender.map((managementLink, idx) => {
            var isActiveLink = managementLink.link === activeLink;
            var activeColor = isActiveLink
              ? "rgb(0, 167, 111)"
              : "rgb(99, 115, 129)";
            return (
              <Link
                to={managementLink.link}
                key={idx}
                className={classes.leftsideNavLink}
              >
                <ListItem
                  className={isActiveLink ? classes.activeLinkBackground : ""}
                >
                  <ListItemIcon style={{ minWidth: "unset", marginRight: 16 }}>
                    {managementLink.link ? (
                      <managementLink.icon
                        fill={activeColor}
                        stroke={activeColor}
                      />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.leftsideNavLinkLabel}
                    style={{ color: activeColor }}
                    key={idx}
                    primary={managementLink.label}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

const LeftSideNav = (props) => {
  var classes = useStyles();
  const { layoutOptions } = props;
  return (
    <Box style={props.style ?? {}} className={classes.leftSideNavWrapper}>
      <IconButton
        className={classes.leftCollapseBtn}
        style={{
          left: layoutOptions.desktopLayoutOptions?.leftNavFullOpen ? 267 : 74,
        }}
        onClick={() =>
          props.setDesktopLayoutOptions({
            leftNavFullOpen:
              !layoutOptions.desktopLayoutOptions?.leftNavFullOpen,
          })
        }
      >
        {layoutOptions.desktopLayoutOptions?.leftNavFullOpen ? (
          <LeftArrowIcon fontSize="inherit" />
        ) : (
          <RightArrowIcon fontSize="inherit" />
        )}
      </IconButton>
      <LeftNavRaw {...props} isDesktop={true} />
    </Box>
  );
};
const LeftNavDrawer = (props) => {
  return (
    <LeftDrawer>
      <LeftNavRaw />
    </LeftDrawer>
  );
};
const mapStoreToProps = (store) => ({
  layoutOptions: store.layoutReducer,
  user: store.userReducer.user,
});
const mapActionsToProps = {
  setMobileLayoutOptions,
  setDesktopLayoutOptions,
};
const exportable = {
  LeftSideNav: connect(mapStoreToProps, mapActionsToProps)(LeftSideNav),
  LeftNavDrawer: connect(mapStoreToProps, mapActionsToProps)(LeftNavDrawer),
};
export default exportable;
