import { combineReducers } from "redux";
import userReducer from "./userReducer/reducer";
import layoutReducer from "./layoutReducer/reducer";
import productsReducer from "./productsReducer/reducer";
import categoriesReducer from "./categoriesReducer/reducer";
import membersReducer from "./membersReducer/reducer";

const reducer = combineReducers({
  userReducer,
  layoutReducer,
  productsReducer,
  categoriesReducer,
  membersReducer,
});
export default reducer;
