import React from "react";
import { connect } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  changeFocusedTab,
  toggleMemberModal,
} from "../../../../redux/membersReducer/action";

import HeaderWithBreadcrums from "../../components/HeaderWithBreadcrums/HeaderWithBreadcrums";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import ExistingMembers from "./Tabs/ExistingMembers";
import PendingInvitations from "./Tabs/PendingInvitations";

const Members = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <HeaderWithBreadcrums
        cta={
          <CustomButton
            label="New Member"
            onClick={props.toggleMemberModal}
            icon={<AddIcon color="inherit" />}
          />
        }
      />
      <Tabs
        value={props.focusedTab}
        onChange={(_, newValue) => props.changeFocusedTab(newValue)}
        aria-label="basic tabs example"
        classes={{ indicator: classes.tabIndicator }}
      >
        <Tab
          label="Exisiting Members"
          value="EXISTING_MEMBERS"
          className={classes.tab}
        />
        <Tab
          label="Pending Invites"
          value="PENDING_INVITES"
          className={classes.tab}
        />
      </Tabs>
      <Box
        className={
          props.focusedTab === "EXISTING_MEMBERS"
            ? classes.visibleTab
            : classes.invisibleTab
        }
      >
        <ExistingMembers />
      </Box>
      <Box
        className={
          props.focusedTab === "PENDING_INVITES"
            ? classes.visibleTab
            : classes.invisibleTab
        }
      >
        <PendingInvitations />
      </Box>
    </React.Fragment>
  );
};
const mapStoreToProps = (store) => ({
  ...store.membersReducer,
});
const mapActionsToProps = {
  toggleMemberModal: toggleMemberModal,
  changeFocusedTab: changeFocusedTab,
};
export default connect(mapStoreToProps, mapActionsToProps)(Members);
