import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  sideTray: {
    width: "80vw",
    height: "100vh",
    overflow: "hidden",
    "@media (max-width: 800px)": {
      width: "100vw",
    },
  },
  trayHeader: {
    backgroundColor: "rgb(244, 246, 248)",
    padding: "12px 25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  trayHeadline: {
    fontSize: 18,
  },
  trayDescription: {
    fontSize: 14,
    fontWeight: 300,
  },
  trayBody: {
    padding: 20,
    overflow: "scroll",
    minHeight: "calc(100vh - 140px)",
  },
  trayFooter: {
    backgroundColor: "rgb(244, 246, 248)",
    padding: "12px 25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  productImageryWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  productImagesWrapper: {
    width: 400,
    height: 400,
    flexShrink: 0,
  },
  productKPIs: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  secondaryHeadlines: {
    color: "rgb(33, 43, 54) !important",
    fontSize: 14,
    fontWeight: 600,
    marginTop: 10,
  },
  descriptionEditior: {
    "& .ql-toolbar": {
      borderRadius: "8px 8px 0 0",
    },
    "& .ql-container": {
      borderRadius: "0 0 8px 8px",
      backgroundColor: "#F6F7F9",
      minHeight: 160,
    },
    "& .ql-container > .ql-editor.ql-blank::before": {
      fontStyle: "normal",
      color: "rgb(145, 158, 171)",
    },
  },
  rowAlignment: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  imageDropZone: {
    border: "2px dashed rgba(145, 158, 171, 0.2)",
    minHeight: 350,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& h4": {
      marginTop: 10,
      fontWeight: 600,
      color: "rgb(33, 43, 54)",
    },
    "& button": {
      color: "#00A76F",
      textTransform: "none",
      textDecoration: "underline",
      padding: "6px 0",
      lineHeight: "unset",
    },
  },
  productImage: {
    position: "relative",
    "&:hover div": {
      display: "flex",
      cursor: "pointer",
    },
  },
  removeImageOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0, 0.5)",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default useStyles;
