import { AdminRoles } from "./constants";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDateStringFromTimeStamp = (timestamp) => {
  var date = new Date(timestamp);
  return `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
};

export const getTimeStringFromTimeStamp = (timestamp) => {
  var date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const readableAdminRoles = (adminRole) => {
  switch (adminRole) {
    case AdminRoles.admin:
      return "Admin";
    case AdminRoles.orderManager:
      return "Order Manager";
    case AdminRoles.productManager:
      return "Product Manager";
    case AdminRoles.superAdmin:
      return "Super Admin";
    default:
      return "";
  }
};
