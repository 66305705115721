import MEMBER_ACTIONS from "./actionTypes";

const initialState = {
  loadMembers: true,
  loadInvites: true,
  membersLoading: false,
  invitationsLoading: false,
  invitationListError: null,
  memberListError: null,
  focusedMember: null,
  refreshMembers: null,
  focusedInvitation: null,
  refreshInvites: null,
  invitationsList: [],
  membersList: [],
  openMembersModal: false,
  focusedTab: "EXISTING_MEMBERS",
};

const reducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case MEMBER_ACTIONS.SET_MEMBERS_LIST:
      newState.membersList = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_MEMBERS_LOADING:
      newState.membersLoading = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_FETCH_ERROR:
      newState.error = action.payload;
      return newState;
    case MEMBER_ACTIONS.TOGGLE_MEMBER_MODAL:
      newState.openMembersModal = !newState.openMembersModal;
      newState.focusedMember = newState.openMembersModal
        ? newState.focusedMember
        : null;
      return newState;
    case MEMBER_ACTIONS.SET_MEMBERS_FETCH_ERROR:
      newState.memberListError = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_FOCUSED_MEMBER:
      newState.focusedMember = action.payload;
      return newState;
    case MEMBER_ACTIONS.REFRESH_MEMBERS_LIST:
      newState.refreshMembers = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_FOCUSED_INVITE:
      newState.focusedInvitation = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_INVITES_LOADING:
      newState.invitationsLoading = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_FOCUSED_TAB:
      newState.focusedTab = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_LOAD_MEMBERS:
      newState.loadMembers = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_LOAD_INVITATIONS:
      newState.loadInvites = action.payload;
      return newState;
    case MEMBER_ACTIONS.SET_INVITES_LIST:
      newState.invitationsList = action.payload;
      return newState;
    case MEMBER_ACTIONS.REFRESH_INVITES_LIST:
      newState.refreshInvites = action.payload;
      return newState;
    default:
      return newState;
  }
};

export default reducer;
