import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  carouselWrapper: {
    margin: "20px 25px",
    "& .slick-arrow": {
      fontSize: 14,
      color: "rgb(145, 158, 171)",
    },
    "& .slick-arrow .slick-prev": {},
    "& .slick-dots": {
      margin: "5px auto",
    },
    "& .slick-dots li button:before": {
      fontSize: 10,
    },
    "& .slick-dots li.slick-active button:before": {
      color: "rgb(0, 167, 111)",
    },
    "& img": {
      objectFit: "contain",
      maxWidth: "100%",
    },
  },
}));

export default useStyles;
