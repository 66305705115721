export const gmcAvailableOptions = {
  // gmc = google merchant center
  ageGroup: [
    { label: "Newborn", value: "newborn" }, // 0 - 3 months
    { label: "Infant", value: "infant" }, // 3 - 12 months
    { label: "Toddler", value: "toddler" }, // 1 - 5 years
    { label: "Kids", value: "kids" }, // 5 - 13 years
    { label: "Adult", value: "adult" }, // teens or older
  ],
  condition: [
    { label: "New", value: "new" }, // Brand new, original, unopened packaging
    { label: "Refurbished", value: "refurbished" }, // Professionally restored to working order
    { label: "Used", value: "used" }, // Previously used
  ],
  targetCountry: [
    { label: "IN", value: "in" },
    { label: "US", value: "us" },
  ],
  contentLanguage: [{ label: "English", value: "en" }],
  currency: [
    { label: "INR", value: "inr" },
    { label: "USD", value: "usd" },
  ],
  shipsFromCountry: [{ label: "IN", value: "in" }],
  googleProductCategory: [{ label: "1371", value: 1371 }],
  productType: [
    {
      label:
        "Arts & Entertainment > Party & Celebration > Party Supplies > Invitations",
      value:
        "Arts & Entertainment > Party & Celebration > Party Supplies > Invitations",
    },
  ],
};
