import { AppBar, Toolbar } from "@mui/material";
import React from "react";

const Header = (props) => {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="transparent"
      sx={{
        backgroundColor: "rgb(255,255,255,0.8)",
        backdropFilter: "blur(6px)",
      }}
      {...props}
    >
      <Toolbar></Toolbar>
    </AppBar>
  );
};

export default Header;
