import { useCallback, useState } from "react";
import {
  API_SERVER_URL,
  getUrlWithSearchParams,
  useAsyncFetchWithToken,
} from "../../../../utils/urlHelpers";

const useCategories = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const { run } = useAsyncFetchWithToken();
  const fetchAllCategories = useCallback(async () => {
    setAllCategories([]);
    let allCat = [];
    let currentPage = 0;
    setLoading(true);
    while (true) {
      const pageParams = {
        page: currentPage,
        limit: 100,
      };
      const response = await run(
        "GET",
        getUrlWithSearchParams(`${API_SERVER_URL}/manage/category`, pageParams)
      );
      if (!response.error && response.data) {
        allCat = allCat.concat(response.data?.data ?? []);
        if (allCat.length < response.data?.totalResults) {
          currentPage++;
          //   break;
        } else {
          break;
        }
        // extreme check to prevent lots of api calls...
        if (currentPage === 20) {
          break;
        }
      } else {
        break;
      }
    }
    setAllCategories([...allCat]);
    setLoading(false);
  }, [run]);
  return {
    categoriesLoading: loading,
    allCategories: allCategories,
    fetchAllCategories,
  };
};

export default useCategories;
