import { Alert, Snackbar } from "@mui/material";
import { createContext, useState } from "react";

const SnackbarContext = createContext(null);

const SnackbarProvider = ({ children }) => {
  const [snackbarOptions, setSnackbarOptions] = useState({
    message: "",
    open: false,
    anchorOrigin: { horizontal: "center", vertical: "bottom" },
    autoHideDuration: 2000,
    snackbarType: "",
  });
  const showErrorSnackbar = (message) => {
    setSnackbarOptions({
      ...snackbarOptions,
      open: true,
      message,
      snackbarType: "error",
    });
  };
  const showSuccessSnackbar = (message) => {
    setSnackbarOptions({
      ...snackbarOptions,
      open: true,
      message,
      snackbarType: "success",
    });
  };
  const showWarningSnackbar = (message) => {
    setSnackbarOptions({
      ...snackbarOptions,
      open: true,
      message,
      snackbarType: "warning",
    });
  };
  const handleClose = () => {
    setSnackbarOptions({ ...snackbarOptions, open: false });
  };
  const snackbarMethods = {
    showErrorSnackbar,
    showSuccessSnackbar,
    showWarningSnackbar,
  };
  return (
    <SnackbarContext.Provider value={snackbarMethods}>
      {children}
      <Snackbar {...snackbarOptions} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackbarOptions.snackbarType || "info"}
          sx={{ width: "100%" }}
        >
          {snackbarOptions.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
