import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const CustomTableLoading = ({ loadingText }) => {
  return (
    <Box
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
        minHeight: "calc(100vh - 258px)",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <Typography component="span">{loadingText ?? "Loading..."}</Typography>
    </Box>
  );
};

export default CustomTableLoading;
