import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableRow,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { CustomTable } from "../../../../../components/CTable/CustomTables";
import CustomTableHeader from "../../../../../components/CTable/components/CustomTableHeader";
import { IconButton, TableCell, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  getDateStringFromTimeStamp,
  getTimeStringFromTimeStamp,
  readableAdminRoles,
} from "../../../../../utils/commonHelpers";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/ConfirmationDialog";
import {
  API_SERVER_URL,
  getUrlWithSearchParams,
  useAsyncFetchWithToken,
} from "../../../../../utils/urlHelpers";
import { SnackbarContext } from "../../../../../components/SnackbarContext/SnackbarContext";
import { connect } from "react-redux";
import useStyles from "../styles";
import { tableHeaderOptions } from "../defaults";
import {
  refreshMembersList,
  setFocusedMember,
  setLoadMembers,
  setMembersList,
  setMembersLoading,
  toggleMemberModal,
  setMembersListError,
} from "../../../../../redux/membersReducer/action";

const ColumnsCount = 7;

const ExistingMembers = (props) => {
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const classes = useStyles();
  const [memberOptionAnchor, setMemberOptionAnchor] = useState(null);
  const [searchDetails, setSearchDetails] = useState({});
  const [selectedMembersIds, setSelectedMembersIds] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { run } = useAsyncFetchWithToken();
  useEffect(() => {
    const ac = new AbortController();
    if (props.loadMembers || props.refreshMembers) {
      var params = {
        ...searchDetails,
      };
      var urlToHit = getUrlWithSearchParams(
        `${API_SERVER_URL}/manage/members`,
        params
      );
      const fetchMembers = async () => {
        props.setMembersLoading(true);
        const { data, error } = await run("GET", urlToHit);
        if (data && !error) {
          props.setMembersList([...data?.data]);
          props.setLoadMembers(false);
        }
        props.setMembersListError(error);
        props.setMembersLoading(false);
      };
      fetchMembers();
    }
    return () => ac.abort();
  }, [searchDetails, props.refreshMembers, props.loadMembers]);

  useEffect(() => {
    if (!props.membersLoading && props.memberListError) {
      showErrorSnackbar(props.memberListError || "Something went wrong!");
    }
  }, [props.memberListError, props.membersLoading]);
  const onSelectAllClick = () => {
    var allMembersIds = props.membersList.map((x) => x._id);
    if (
      selectedMembersIds.length === props.membersList.length ||
      selectedMembersIds.length > 0
    ) {
      setSelectedMembersIds([]);
    } else {
      setSelectedMembersIds(allMembersIds);
    }
  };
  const onMemberSelect = (memberId) => {
    var atIndex = selectedMembersIds.indexOf(memberId);
    if (atIndex > -1) {
      selectedMembersIds.splice(atIndex, 1);
      setSelectedMembersIds([...selectedMembersIds]);
    } else {
      selectedMembersIds.push(memberId);
      setSelectedMembersIds([...selectedMembersIds]);
    }
  };
  const tableHeader = (
    <CustomTableHeader
      haveBulkOptions={selectedMembersIds.length}
      haveCheckbox={true}
      bulkOperation={
        <React.Fragment>
          <TableCell size="small" colSpan={ColumnsCount - 2}>
            <Typography className={classes.bulkEditText}>
              {selectedMembersIds.length} Selected
            </Typography>
          </TableCell>
          <TableCell size="small">
            <IconButton
              className={classes.bulkEditBtn}
              onClick={() => setShowDeleteModal(true)}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </React.Fragment>
      }
      checkboxChecked={
        selectedMembersIds.length > 0 &&
        selectedMembersIds.length === props.membersList.length
      }
      checkboxIndeterminate={
        selectedMembersIds.length > 0 &&
        selectedMembersIds.length !== props.membersList.length
      }
      headerOptions={tableHeaderOptions}
      onBulkSelect={onSelectAllClick}
    />
  );
  const onMenuOpenClick = (e, member) => {
    setMemberOptionAnchor(e.currentTarget);
    props.setFocusedMember(member);
  };
  const onMenuCloseClick = () => {
    setMemberOptionAnchor(null);
    props.setFocusedMember(null);
  };
  const onMemberEditClick = () => {
    setMemberOptionAnchor(null);
    props.toggleMemberModal();
  };
  const onMemberDeleteClick = () => {
    setShowDeleteModal(true);
    setMemberOptionAnchor(null);
  };
  const onConfirmDelete = async () => {
    setShowDeleteModal(false);
    var memberIds = [];
    if (selectedMembersIds?.length > 0) {
      memberIds = selectedMembersIds;
    } else if (props.focusedMember) {
      memberIds = [props.focusedMember._id];
    }
    const { data, error } = await run(
      "DELETE",
      `${API_SERVER_URL}/manage/members`,
      { memberIds }
    );
    if (data && !error) {
      showSuccessSnackbar(
        `Successfully removed ${data?.data?.deleteCount} members!`
      );
      setSelectedMembersIds([]);
      props.refreshMembersList();
      props.setFocusedMember(null);
    }
  };
  return (
    <React.Fragment>
      <CustomTable
        tableHeader={tableHeader}
        loading={props.membersLoading}
        tableBody={
          <TableBody>
            {props.membersList.map((member, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>
                    <Checkbox
                      className={classes.checkbox}
                      onChange={() => onMemberSelect(member._id)}
                      checked={selectedMembersIds.includes(member._id)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <img
                      className={classes.avatar}
                      src={
                        member?.imageUrl
                          ? member.imageUrl
                          : "/brandAssets/memberAvatar.png"
                      }
                      alt=""
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography className={classes.memberName}>
                        {member.name}
                      </Typography>
                      <Typography className={classes.memberEmail}>
                        {member.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.lightHeadline}>
                      {`+91-${member.phone_number}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.lightHeadline}>
                      {getDateStringFromTimeStamp(member.createdAt)}
                    </Typography>
                    <Typography className={classes.dullDescription}>
                      {getTimeStringFromTimeStamp(member.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={
                        member.email === props.user?.email
                          ? classes.chipActive
                          : classes.chipInactive
                      }
                    >
                      {readableAdminRoles(member.role)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.moreOptionBtn}>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={(e) => onMenuOpenClick(e, member)}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
      />
      <Menu
        anchorEl={memberOptionAnchor}
        open={Boolean(memberOptionAnchor)}
        onClose={onMenuCloseClick}
      >
        <MenuItem onClick={onMemberEditClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <BorderColorOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>
            Edit Role
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onMemberDeleteClick}>
          <ListItemIcon style={{ color: "rgb(33, 43, 54)" }}>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.lightHeadline}>Remove</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteModal}
        description={`This will remove ${
          selectedMembersIds.length ? selectedMembersIds.length : 1
        } members(s). Are you sure you want to proceed?`}
        onClose={() => setShowDeleteModal(false)}
        onCancelClick={() => setShowDeleteModal(false)}
        onConfirmClick={onConfirmDelete}
      />
    </React.Fragment>
  );
};
const mapStoreToProps = (store) => ({
  ...store.membersReducer,
  ...store.userReducer,
});
const mapActionsToProps = {
  toggleMemberModal: toggleMemberModal,
  setFocusedMember: setFocusedMember,
  setMembersList: setMembersList,
  setMembersLoading: setMembersLoading,
  setLoadMembers: setLoadMembers,
  refreshMembersList: refreshMembersList,
  setMembersListError: setMembersListError,
};
export default connect(mapStoreToProps, mapActionsToProps)(ExistingMembers);
