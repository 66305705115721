import * as LocalStorageHelper from "../../utils/localStorageHelper";
import USER_ACTIONS from "./actionTypes";

const initialState = {
  user: LocalStorageHelper.getUserFromLS(),
  isUserLoading: true,
  accessToken: LocalStorageHelper.getAccessTokenFromLS(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_USER:
      LocalStorageHelper.setUserInLS(action.payload);
      return { ...state, user: action.payload };
    case USER_ACTIONS.SET_LOADING:
      return { ...state, isUserLoading: action.payload };
    case USER_ACTIONS.SET_ACCESS_TOKEN:
      LocalStorageHelper.setAccessTokenInLS(action.payload);
      return { ...state, accessToken: action.payload };
    case USER_ACTIONS.CLEAR_USER:
      LocalStorageHelper.setUserInLS(null);
      LocalStorageHelper.setAccessTokenInLS(null);
      return { ...state, user: null, accessToken: null };
    default:
      return state;
  }
};

export default reducer;
