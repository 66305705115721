import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => {
  return {
    leftSideNavWrapper: {
      position: "relative",
      padding: 16,
    },
    leftCollapseBtn: {
      position: "fixed !important",
      top: 32,
      zIndex: 1200,
      backgroundColor: "#fff !important",
      padding: "4px !important",
      fontSize: "1rem !important",
      border: "1px dashed rgba(145, 158, 171, 0.2) !important",
    },
    mainSectionHeading: {
      fontWeight: "700 !important",
      fontSize: "11px !important",
      color: "rgb(145, 158, 171) !important",
    },
    displayNone: {
      display: "none",
    },
    leftsideNavLink: {
      textDecoration: "none !important",
      display: "flex",
    },
    leftsideNavLinkLabel: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      lineHeight: "1.57143",
      fontSize: "0.875rem !important",
      fontWeight: 500,
    },
    brandingArea: {
      padding: 10,
    },
    activeLinkBackground: {
      backgroundColor: "rgba(0, 167, 111, 0.08) !important",
      borderRadius: "8px !important",
    },
  };
});
export default useStyles;
